import { CurveId, GraphCurve } from '@astrion-webtools/graph';
import { SensorId } from '@features/sensors/shared/interfaces/sensor.interface';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const SensorGraphsActions = createActionGroup({
  source: 'SensorGraphs',
  events: {
    setCurrentSensor: props<{ sensorId: SensorId }>(),
    clearCurves: emptyProps(),
    removeCurve: props<{ curveId: string }>(),
    addCurve: props<{ curveId: CurveId; curve: GraphCurve }>(),
  },
});
