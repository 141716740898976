import { Component, input, output } from '@angular/core';
import { FilterModel } from '@components/filter-dialog/filter-dialog.component';
import { MaterialModule } from '@modules/material.module';
import { FilterDialogService } from '@services/filter-dialog.service';

@Component({
  selector: 'app-filter',
  standalone: true,
  providers: [FilterDialogService],
  imports: [MaterialModule],
  templateUrl: './filter.component.html',
  styles: ``,
})
export class FilterComponent {
  values = input.required<number[]>();
  inputFilter = input.required<FilterModel | undefined>();
  outputFilter = output<FilterModel | undefined>();

  constructor(private filterDialogService: FilterDialogService) {}

  async openFilterDialog(event: MouseEvent) {
    event.stopPropagation();

    const values = this.values();
    const min = Math.min(...values);
    const max = Math.max(...values);

    const filter = await this.filterDialogService.open(
      { min, max, filter: this.inputFilter() },
      event.clientX,
      event.clientY
    );

    if (filter) {
      this.outputFilter.emit(filter.min <= min && filter.max >= max ? undefined : filter);
    }
  }
}
