import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, output, signal } from '@angular/core';
import { Folder } from '@features/folders/shared/interface/folder.interface';
import { Sensor } from '@features/sensors/shared/interfaces/sensor.interface';
import { MaterialModule } from '@modules/material.module';

@Component({
  selector: 'app-folders-bar',
  templateUrl: './folders-bar.component.html',
  standalone: true,
  imports: [MaterialModule, CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      .m3-headline-small-button {
        font-size: 24px !important;
        font-weight: 400 !important;
        --mdc-text-button-label-text-size: 24px;
        --mdc-text-button-label-text-weight: 400;
      }
      .m3-headline-small-icon {
        width: 24px !important;
        height: 24px !important;
        font-size: 24px !important;
      }
    `,
  ],
  animations: [
    trigger('path', [
      state(
        'path',
        style({
          opacity: 1,
        })
      ),
      state(
        'filename',
        style({
          opacity: 0,
        })
      ),
      transition('filename <=> path', animate(200)),
    ]),
    trigger('filename', [
      state(
        'path',
        style({
          opacity: 0,
        })
      ),
      state(
        'filename',
        style({
          opacity: 1,
        })
      ),
      transition('filename <=> path', animate(200)),
    ]),
  ],
})
export class FoldersBarComponent {
  folders = input.required<Folder[] | null>();
  sensor = input<Sensor>();
  filename = input<string | null>(null);

  folderClicked = output<Folder>();
  sensorClicked = output<Sensor>();

  mouseInNav = signal<boolean>(true);
  loading = computed(() => this.folders() === null);
  fullFilename = computed(() => this.filename() && (this.mouseInNav() || this.loading()));
  state = computed(() => (this.fullFilename() ? 'filename' : this.filename() ? 'path' : 'none'));
}
