<div class="flex h-full w-full flex-col gap-1">
  <div class="flex justify-center text-lg shadow-slate-300 text-shadow-sm">{{ graphTitle() }}</div>
  <lib-gl-graph
    class="flex-grow"
    [id]="id()"
    [data]="data()"
    yTitle="Frequency (Hz)"
    xTitle="Time (s)"
    group="stationarity"
    [xMeanTicks]="5" />
</div>
