import { BaseSelectors } from '@app-types/extra-selectors-factory';
import { Folder } from '@features/folders/shared/interface/folder.interface';
import { foldersFeature } from '@features/folders/shared/store/folders.feature';
import { getPathFromRoot, getPathFromRootWithId } from '@features/folders/shared/utils/folders-uri';
import { createSelector } from '@ngrx/store';
import { LoadingState } from '@shared/interfaces/loading-state';
import { selectRouteParam } from '@store/router.selectors';

import { Sensor, SensorId } from '../interfaces/sensor.interface';
import { name, SensorsState } from './sensors.state';

export const extraSelectors = ({ selectSensors, selectLoadingState }: BaseSelectors<typeof name, SensorsState>) => {
  const selectCurrentSensorId = createSelector(selectRouteParam('id'), id => {
    return id as unknown as SensorId | undefined;
  });
  const selectCurrentSensor = createSelector(selectCurrentSensorId, selectSensors, (id, sensors) =>
    sensors.find(s => s.id === id)
  );
  const selectCurrentSensorFolder = createSelector(
    selectCurrentSensor,
    foldersFeature.selectFlattenedFoldersTree,
    (sensor, flatTree) => (sensor ? flatTree.folders[sensor.folderId] : undefined)
  );
  const selectCurrentSensorPath = createSelector(
    selectCurrentSensorFolder,
    foldersFeature.selectFlattenedFoldersTree,
    (folder: Folder | undefined, foldersTree): Folder[] | undefined => {
      return folder ? getPathFromRoot(folder, foldersTree) : undefined;
    }
  );

  const selectSensor = (sensorId: SensorId) =>
    createSelector(selectSensors, sensors => sensors.find(s => s.id == sensorId));

  const selectSensorPath = (sensorId: SensorId) =>
    createSelector(
      selectSensor(sensorId),
      foldersFeature.selectFlattenedFoldersTree,
      (sensor: Sensor | undefined, foldersTree): Folder[] | null => {
        return sensor ? getPathFromRootWithId(sensor.folderId, foldersTree) : null;
      }
    );

  return {
    selectIsLoading: createSelector(selectLoadingState, loadigState => loadigState === LoadingState.Loading),
    selectInitialized: createSelector(selectLoadingState, loadigState => loadigState === LoadingState.Loaded),
    selectSensor,
    selectSensorPath,
    selectCurrentSensorId,
    selectCurrentSensor,
    selectCurrentSensorPath,
  };
};
