import { LoadingState } from '@shared/interfaces/loading-state';

import { ReportPages } from '../interface/report-page';

export const REPORT_NAMES = {
  overview: {
    path: 'overview',
    anchors: {
      signal: 'signal',
      metadata: 'metadata',
    },
  },
  dataValidation: {
    path: 'data-validation',
    anchors: {
      timeSaturation: 'time-saturation',
      shannonTest: 'shannon-test',
      stationarity: 'stationarity',
    },
  },
  peakIdentification: {
    path: 'peak-identification',
    anchors: {
      cycles: 'spectrums',
      fusion: 'fusion',
      harmonics: 'harmonics',
    },
  },
};

export const REPORT_PAGES: ReportPages = [
  {
    name: 'Overview',
    path: REPORT_NAMES.overview.path,
    icon: 'landscape',
    color: '#4EBA77',
    anchors: [
      {
        name: 'Signal',
        icon: 'timeline',
        fragment: REPORT_NAMES.overview.anchors.signal,
        loadingState: LoadingState.Unknown,
      },
      {
        name: 'Metadata',
        icon: 'info',
        fragment: REPORT_NAMES.overview.anchors.metadata,
        loadingState: LoadingState.Unknown,
      },
    ],
    loadingState: LoadingState.Unknown,
  },
  {
    name: 'Data validation',
    icon: 'playlist_add_check',
    path: REPORT_NAMES.dataValidation.path,
    color: '#62C5DA',
    anchors: [
      {
        name: 'Time saturation',
        icon: 'vertical_distribute',
        fragment: REPORT_NAMES.dataValidation.anchors.timeSaturation,
        loadingState: LoadingState.Unknown,
      },
      {
        name: 'Shannon test',
        icon: 'insights',
        fragment: REPORT_NAMES.dataValidation.anchors.shannonTest,
        loadingState: LoadingState.Unknown,
      },
      {
        name: 'Stationarity',
        icon: 'assessment',
        fragment: REPORT_NAMES.dataValidation.anchors.stationarity,
        loadingState: LoadingState.Unknown,
      },
    ],
    loadingState: LoadingState.Unknown,
  },
  {
    name: 'Peak identification',
    icon: 'query_stats',
    path: REPORT_NAMES.peakIdentification.path,
    color: '#13bed1',
    anchors: [
      {
        name: 'Spectrums',
        icon: 'ssid_chart', // TODO - use 'monitoring' icon when transitionning to material 3 symbols
        fragment: REPORT_NAMES.peakIdentification.anchors.cycles,
        loadingState: LoadingState.Unknown,
      },
      {
        name: 'Fusion',
        icon: 'compress',
        fragment: REPORT_NAMES.peakIdentification.anchors.fusion,
        loadingState: LoadingState.Unknown,
      },
      {
        name: 'Harmonics',
        icon: 'bar_chart',
        fragment: REPORT_NAMES.peakIdentification.anchors.harmonics,
        loadingState: LoadingState.Unknown,
      },
    ],
    loadingState: LoadingState.Unknown,
  },
];
