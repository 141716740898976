import { FolderId } from '@features/folders/shared/interface/folder.interface';

import { ExplorerItemId } from '../interface/explorer-item.interface';

export const name = 'files-explorer';

export interface FilesExplorersState {
  currentlyEditedItemId: ExplorerItemId | null;
  isLoading: boolean;
  currentFolderId: FolderId | null;
}

export const FILES_EXPLORER_INITIAL_STATE: FilesExplorersState = {
  currentlyEditedItemId: null,
  isLoading: true,
  currentFolderId: null,
};
