import { Guid } from '@astrion-webtools/graph';
import { FolderId, mapFolderId } from '@features/folders/shared/interface/folder.interface';

interface SensorProperties {
  name: string;
  sensorType: string;
  model: string | null;
  component: string | null;
  collectPoint: string | null;
  orientation: string | null;
}

export type SensorId = Guid;

export interface SensorDto extends SensorProperties {
  id: SensorId;
  folderId: FolderId | null;
  signalsCount: number;
}

export interface SensorPayload extends SensorProperties {
  folderId: FolderId | null;
}

export interface Sensor extends SensorProperties {
  id: SensorId;
  folderId: FolderId;
  signalsCount: number;
}

export interface SensorSummary {
  id: SensorId | FolderId;
  name: string;
  signalsCount: number;
}

export const mapSensorFromDto = (dto: SensorDto): Sensor => ({
  ...dto,
  folderId: mapFolderId(dto.folderId),
});
