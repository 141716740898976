import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { ExplorerTopBarComponent } from '@features/files-explorer/components/explorer-top-bar/explorer-top-bar.component';
import { FilesExplorerActions } from '@features/files-explorer/shared/store/files-explorer.actions';
import { Folder } from '@features/folders/shared/interface/folder.interface';
import { sensorSignalsFeature } from '@features/sensor-signals/shared/store/sensor-signals.feature';
import { SensorComponent } from '@features/sensors/components/sensor/sensor.component';
import { SensorsActions } from '@features/sensors/shared/store/sensors.actions';
import { sensorsFeature } from '@features/sensors/shared/store/sensors.feature';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-sensor-view',
  templateUrl: './sensor-view.component.html',
  standalone: true,
  imports: [ExplorerTopBarComponent, SensorComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [],
})
export class SensorViewComponent {
  id = this.store.selectSignal(sensorsFeature.selectCurrentSensorId);
  path = this.store.selectSignal(sensorsFeature.selectCurrentSensorPath);
  sensor = this.store.selectSignal(sensorsFeature.selectCurrentSensor);
  isLoading = computed(() => this.sensorSignalsLoading() || this.sensorsLoading());

  private sensorsLoading = this.store.selectSignal(sensorsFeature.selectIsLoading);
  private sensorSignalsLoading = this.store.selectSignal(sensorSignalsFeature.selectIsLoading);

  constructor(private store: Store) {
    this.store.dispatch(SensorsActions.refreshIfNotInit());
  }

  navigateToFolder(folder: Folder) {
    this.store.dispatch(FilesExplorerActions.navigationToFolderRequested({ folder }));
  }
}
