import { recordDeleteMultiple } from '@tools/utilities/record-utilities';

import { FlattenedFolderTree, FolderId, FoldersMap } from '../interface/folder.interface';
import { getDescentSet } from './tree-paths';

export const deleteFolder = (flattenedFoldersTree: FlattenedFolderTree, folderId: FolderId): FlattenedFolderTree => {
  if (!flattenedFoldersTree.folders[folderId]) {
    return flattenedFoldersTree;
  }

  return deleteFolderWithDescendance(flattenedFoldersTree, folderId, getDescentSet(flattenedFoldersTree, folderId));
};

export const deleteFolderWithDescendance = (
  flattenedFoldersTree: FlattenedFolderTree,
  folderId: FolderId,
  descendance: Set<FolderId>
): FlattenedFolderTree => {
  const folder = flattenedFoldersTree.folders[folderId];

  if (!folder) {
    return flattenedFoldersTree;
  }

  const parentId = folder.parentId;
  if (parentId === folderId) {
    throw new Error('Cannot delete root folder');
  }

  // Filter record and update signalsCount in ancestors
  const folders: FoldersMap = recordDeleteMultiple(flattenedFoldersTree.folders, descendance);
  const children: Record<FolderId, FolderId[]> = filterChildren(
    flattenedFoldersTree.children,
    descendance,
    folderId,
    parentId
  );

  // Update parent isLeaf if necessary
  if (children[parentId].length === 0) {
    folders[parentId] = { ...folders[parentId], isLeaf: true };
  }

  return {
    folders,
    children,
  };
};

function filterChildren(
  children: Record<FolderId, FolderId[]>,
  toRemove: Set<FolderId>,
  deletedId: FolderId,
  deletedParentId: FolderId
): Record<FolderId, FolderId[]> {
  const filtered = recordDeleteMultiple(children, toRemove);
  filtered[deletedParentId] = filtered[deletedParentId].filter(id => id != deletedId);
  return filtered;
}
