import { Injectable } from '@angular/core';
import { AuthActions } from '@features/auth/shared/store/auth.actions';
import { FoldersActions } from '@features/folders/shared/store/folders.actions';
import { catchApiError } from '@modules/error-handling/app-error.operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { concatMap, map } from 'rxjs';

import { mapSensorFromDto, SensorDto } from '../interfaces/sensor.interface';
import { SensorsApiService } from '../services/sensors-api.service';
import { SensorsActions } from './sensors.actions';
import { sensorsFeature } from './sensors.feature';

@Injectable()
export class SensorsEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private sensorsApiService: SensorsApiService
  ) {}
  refreshSensorIfNotInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SensorsActions.refreshIfNotInit),
      map(() => SensorsActions.sensorsFetchRequestedIfNotInit())
    )
  );
  refreshFolderIfNotInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SensorsActions.refreshIfNotInit),
      map(() => FoldersActions.foldersFetchRequestedIfNotInit())
    )
  );
  fetchSensorsIfNotInit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SensorsActions.sensorsFetchRequestedIfNotInit),
      concatLatestFrom(() => this.store.select(sensorsFeature.selectInitialized)),
      map(([, initialized]) => (initialized ? SensorsActions.noop() : SensorsActions.sensorsFetchRequested()))
    );
  });
  fetchSensorsEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SensorsActions.sensorsFetchRequested, AuthActions.projectChanged),
      concatMap(() =>
        this.sensorsApiService.getAllSensors().pipe(
          map((sensors: SensorDto[]) => SensorsActions.sensorsFetched({ sensors: sensors.map(mapSensorFromDto) })),
          catchApiError(false, () => SensorsActions.sensorsFetchFailed())
        )
      )
    );
  });
  createSensorEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SensorsActions.sensorCreateRequested),
      concatMap(({ sensor }) =>
        this.sensorsApiService.createSensor(sensor).pipe(
          map(created => SensorsActions.sensorCreated({ sensor: mapSensorFromDto(created) })),
          catchApiError(false, () => SensorsActions.sensorCreateFailed())
        )
      )
    );
  });
  deleteSensorEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SensorsActions.sensorDeleteRequested),
      concatMap(({ id, folderId, force }) =>
        this.sensorsApiService.deleteSensor(id, force).pipe(
          map(() => SensorsActions.sensorDeleted({ id, folderId })),
          catchApiError(false, () => SensorsActions.sensorCreateFailed())
        )
      )
    );
  });
}
