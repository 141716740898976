import { Folder, FolderId } from '@features/folders/shared/interface/folder.interface';
import { foldersFeature } from '@features/folders/shared/store/folders.feature';
import { FoldersUri, getFolderId, getPathFromRoot } from '@features/folders/shared/utils/folders-uri';
import { getDescentSet } from '@features/folders/shared/utils/tree-paths';
import { Sensor } from '@features/sensors/shared/interfaces/sensor.interface';
import { sensorsFeature } from '@features/sensors/shared/store/sensors.feature';
import { createSelector } from '@ngrx/store';
import { selectUrl } from '@store/router.selectors';
import { recordMap } from '@tools/utilities/record-utilities';

import {
  ExplorerFolderItem,
  ExplorerItem,
  ExplorerItemType,
  ExplorerSensorItem,
} from '../interface/explorer-item.interface';
import { FILES_EXPLORER_ROOT } from '../utils/files-explorer-root';

const folder2item = (folder: Folder, hasSensor: boolean, sensorSignalsCount: number): ExplorerFolderItem => ({
  type: ExplorerItemType.Folder,
  object: folder,
  hasSensor,
  sensorSignalsCount,
});

const sensor2item = (sensor: Sensor): ExplorerSensorItem => ({
  type: ExplorerItemType.Sensor,
  object: sensor,
});

const appendRoot = (path: string) => `/${FILES_EXPLORER_ROOT}${path}`;

/**
 * append files-explorer root path to base folder paths
 */
export const selectFoldersExplorerUris = createSelector(
  foldersFeature.selectFoldersUri,
  foldersUri =>
    ({
      folderIdToUri: recordMap(foldersUri.folderIdToUri, (folderId, path) => [folderId, appendRoot(path)]),
      uriToFolderId: recordMap(foldersUri.uriToFolderId, (path, folderId) => [appendRoot(path), folderId]),
    }) as FoldersUri
);

export const selectCurrentFolderId = createSelector(
  selectUrl,
  selectFoldersExplorerUris,
  (url, foldersUri): FolderId | null => getFolderId(foldersUri, url)
);

export const selectCurrentFolder = createSelector(
  selectCurrentFolderId,
  foldersFeature.selectFlattenedFoldersTree,
  (folderId, flatTree): Folder | null => (folderId ? (flatTree.folders[folderId] ?? null) : null)
);

export const selectUriFoldersPath = createSelector(
  selectCurrentFolder,
  foldersFeature.selectFlattenedFoldersTree,
  (folder: Folder | null, foldersTree): Folder[] | null => {
    return folder ? getPathFromRoot(folder, foldersTree) : null;
  }
);

export const selectFolderUri = (folderId: FolderId) =>
  createSelector(foldersFeature.selectFoldersUri, foldersUri => foldersUri.folderIdToUri[folderId]);

const selectCurrentSubfolders = createSelector(
  foldersFeature.selectFlattenedFoldersTree,
  selectCurrentFolderId,
  (flattenedFoldersTree, currentFolderId): Folder[] =>
    currentFolderId
      ? flattenedFoldersTree.children[currentFolderId].map(folderId => flattenedFoldersTree.folders[folderId])
      : []
);

export const selectExplorerContent = createSelector(
  selectCurrentFolderId,
  selectCurrentSubfolders,
  foldersFeature.selectFlattenedFoldersTree,
  sensorsFeature.selectSensors,
  (folderId, folders, flattenedFoldersTree, sensors): ExplorerItem[][] => {
    const countDescentSensorsSignals = (subFolderId: FolderId) => {
      const descentSet = getDescentSet(flattenedFoldersTree, subFolderId);
      return sensors
        .filter(s => descentSet.has(s.folderId))
        .map(s => s.signalsCount)
        .reduce((p, n) => p + n, 0);
    };
    return [
      folders.map(folder =>
        folder2item(
          folder,
          sensors.some(s => s.folderId === folder.id),
          countDescentSensorsSignals(folder.id)
        )
      ),
      sensors.filter(s => s.folderId === folderId).map(sensor2item),
    ];
  }
);
