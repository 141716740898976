<div class="flex h-full w-full flex-col gap-1">
  <div class="flex justify-center text-lg shadow-slate-300 text-shadow-sm">Normalized variance</div>
  <lib-gl-graph
    class="flex-grow"
    id="stationarity-projection"
    [data]="data()"
    [lines]="lines()"
    xTitle="Variance"
    yTitle="Frequency (Hz)"
    group="stationarity"
    [xMeanTicks]="3"
    lockX />
</div>
