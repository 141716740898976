import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HOME_FOLDER } from '@features/folders/shared/interface/folder.interface';
import { FoldersActions } from '@features/folders/shared/store/folders.actions';
import { foldersFeature } from '@features/folders/shared/store/folders.feature';
import { selectFolderIsLeaf } from '@features/folders/shared/store/folders.selectors';
import { existFolderUrl, getFolderUrl } from '@features/folders/shared/utils/folders-uri';
import { SensorGraphsActions } from '@features/sensor-graphs/shared/store/sensor-graphs.actions';
import { SensorsActions } from '@features/sensors/shared/store/sensors.actions';
import { SignalsActions } from '@features/signals/shared/store/signals.actions';
import { AStrionWebError } from '@modules/error-handling/app-error/app-error.class';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { selectFragment, selectRouteData } from '@store/router.selectors';
import { filter, map, tap } from 'rxjs';

import { FilesExplorerActions } from './files-explorer.actions';
import { filesExplorerFeature } from './files-explorer.feature';
import { selectCurrentFolder, selectFoldersExplorerUris } from './files-explorer.selectors';

@Injectable()
export class FilesExplorerEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store
  ) {}

  closeEditionAfterFolderNameChanged$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FoldersActions.folderUpdated, FoldersActions.folderUpdateFailed),
      map(({ folder }) => FilesExplorerActions.editedItemCleared({ fromId: folder.id }))
    );
  });

  loadContentWhenFoldersFetched$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FoldersActions.foldersFetched, routerNavigatedAction),
      concatLatestFrom(() => [
        this.store.select(selectCurrentFolder),
        this.store.select(selectRouteData),
        this.store.select(foldersFeature.selectInitialized),
      ]),
      map(([, folder, data, initialized]) =>
        data['fileExplorer'] && initialized
          ? FilesExplorerActions.navigationToFolderRequested({ folder: folder ?? HOME_FOLDER })
          : undefined
      ),
      filter(action => action !== undefined),
      map(action => action!) // eslint does not understant the statement rigth above
    );
  });

  fetchSignalsOnCurrentFolderChangeEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FilesExplorerActions.navigationToFolderRequested),
      concatLatestFrom(({ folder }) => [
        this.store.select(selectFolderIsLeaf(folder.id)),
        this.store.select(filesExplorerFeature.selectCurrentFolderId),
      ]),
      map(([{ folder }, isLeaf, cachedFolderId]) => {
        if (isLeaf) {
          if (folder.id === cachedFolderId) {
            return FilesExplorerActions.fetchFolderSkipAlreadyCached({ folder });
          } else {
            return FilesExplorerActions.fetchFolderSkipNoContent({ folder });
          }
        } else {
          return FilesExplorerActions.fetchFolderSkipNoContent({ folder });
        }
      })
    );
  });

  setGraphCurrentSensor$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SignalsActions.sensorSignalsFetched),
      map(({ sensorId: folderId }) => SensorGraphsActions.setCurrentSensor({ sensorId: folderId }))
    );
  });

  navigateAfterFolderCacheFilled$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SignalsActions.sensorSignalsFetched),
        concatLatestFrom(() => [this.store.select(selectFoldersExplorerUris), this.store.select(selectFragment)]),
        tap(([{ sensorId: folderId }, foldersUri, fragment]) => {
          if (existFolderUrl(foldersUri, folderId)) {
            this.router.navigateByUrl(getFolderUrl(foldersUri, folderId, fragment));
          }
        })
      );
    },
    { dispatch: false }
  );

  navigateAfterFolderSkipNoContent$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FilesExplorerActions.fetchFolderSkipNoContent),
        concatLatestFrom(() => [this.store.select(selectFoldersExplorerUris)]),
        tap(([{ folder }, foldersUri]) => this.router.navigateByUrl(getFolderUrl(foldersUri, folder.id)))
      );
    },
    { dispatch: false }
  );

  navigateAfterFolderSkipAlreadyCached$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FilesExplorerActions.fetchFolderSkipAlreadyCached),
        concatLatestFrom(() => [this.store.select(selectFoldersExplorerUris), this.store.select(selectFragment)]),
        tap(([{ folder }, foldersUri, fragment]) =>
          this.router.navigateByUrl(getFolderUrl(foldersUri, folder.id, fragment))
        )
      );
    },
    { dispatch: false }
  );

  navigateOnFolderDeletion$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FoldersActions.folderDeleted),
        concatLatestFrom(() => [this.store.select(selectCurrentFolder), this.store.select(selectFoldersExplorerUris)]),
        tap(([{ parentId }, currentFolder, foldersUri]) => {
          if (currentFolder === null) {
            this.router.navigateByUrl(foldersUri.folderIdToUri[parentId]);
            throw new AStrionWebError('Current folder deleted', 'The folder you were in just got deleted.');
          }
        })
      );
    },
    { dispatch: false }
  );

  refreshFolderIfNotInitEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilesExplorerActions.refreshIfNotInit),
      map(() => FoldersActions.foldersFetchRequestedIfNotInit())
    )
  );

  refreshSensorsIfNotInitEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilesExplorerActions.refreshIfNotInit),
      map(() => SensorsActions.sensorsFetchRequestedIfNotInit())
    )
  );

  refreshFolderEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilesExplorerActions.refresh),
      map(() => FoldersActions.foldersFetchRequested())
    )
  );

  refreshSensors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilesExplorerActions.refresh),
      map(() => SensorsActions.sensorsFetchRequested())
    )
  );
}
