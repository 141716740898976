import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  effect,
  ElementRef,
  input,
  viewChild,
} from '@angular/core';
import { MaterialModule } from '@modules/material.module';

import { CurveLegend } from '../trajectory-graph.interface';

const throttle = (callback: (event: MouseEvent) => void, limit_ms: number) => {
  let last = performance.now();
  return (event: MouseEvent) => {
    const now = performance.now();
    if (now - last >= limit_ms) {
      last = now;
      callback(event);
    }
  };
};

@Component({
  selector: 'app-trajectory-graph-legend',
  standalone: true,
  imports: [MaterialModule],
  templateUrl: './trajectory-graph-legend.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrajectoryGraphLegendComponent {
  legend = input.required<CurveLegend[]>();

  sortedLegend = computed(() =>
    [...this.legend()].sort((a, b) => {
      const displayA = a.display ?? true;
      const displayB = b.display ?? true;
      if (displayA === displayB) {
        return a.id.localeCompare(b.id);
      } else {
        return displayA ? -1 : 1.0;
      }
    })
  );

  main = viewChild.required<ElementRef<HTMLDivElement>>('main');

  isDown = false;
  startX = 0;

  isScrollable = false;
  isScrollableLeft = false;
  isScrollableRight = true;

  constructor(private cd: ChangeDetectorRef) {
    effect(() => {
      const main = this.main();
      const elem = main.nativeElement;
      elem.addEventListener('mousedown', (event: MouseEvent) => this.down(event));
      elem.addEventListener('scrollend', () => this._updateScrollable(elem));
      document.addEventListener('mouseup', () => this.up());
      document.addEventListener(
        'mousemove',
        throttle((event: MouseEvent) => this.move(event), 15)
      );
      this.isScrollable = elem.offsetWidth < elem.scrollWidth;
    });
  }

  down(event: MouseEvent) {
    this.isDown = true;
    this.startX = event.pageX;
  }

  up() {
    this.isDown = false;
  }

  move(event: MouseEvent) {
    if (this.isDown) {
      const elem = this.main().nativeElement;
      const dx = this.startX - event.pageX;
      elem.scrollLeft += 2 * dx;
      this.startX = event.pageX;
    }
  }

  scrollLeft() {
    const elem = this.main().nativeElement;
    elem.scrollTo({ left: elem.scrollLeft - Math.floor(elem.offsetWidth / 2), behavior: 'smooth' });
  }

  scrollRight() {
    const elem = this.main().nativeElement;
    elem.scrollTo({ left: elem.scrollLeft + Math.floor(elem.offsetWidth / 2), behavior: 'smooth' });
  }

  private _updateScrollable(elem: HTMLDivElement) {
    const left = elem.scrollLeft > 0;
    const right = elem.scrollLeft < elem.scrollWidth - elem.offsetWidth;
    if (left !== this.isScrollableLeft || right !== this.isScrollableRight) {
      this.isScrollableLeft = left;
      this.isScrollableRight = right;
      this.cd.detectChanges();
    }
  }
}
