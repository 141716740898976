@let _loading = loading();
@let _canEdit = canEdit();

<div class="relative flex h-full w-full flex-col">
  @if (_loading) {
    <mat-progress-bar class="absolute -top-1 left-0 z-50 w-full" mode="indeterminate" />
  }
  <mat-toolbar class="flex w-full flex-row justify-end">
    <button mat-icon-button matTooltip="Refresh folder content" (click)="refreshClicked.emit()">
      <mat-icon>refresh</mat-icon>
    </button>
    @if (_canEdit) {
      <button mat-icon-button matTooltip="New folder" (click)="openCreateFolderDialog()" [disabled]="_loading">
        <mat-icon>create_new_folder</mat-icon>
      </button>
    }
    @if (_canEdit) {
      <button mat-icon-button matTooltip="New Sensor" (click)="openCreateSensorDialog()" [disabled]="_loading">
        <mat-icon>add</mat-icon>
      </button>
    }
  </mat-toolbar>
  <div class="relative flex h-full w-full flex-col">
    <app-scrollable-bordered-container class="relative flex-grow" [fadeTop]="true" [fadeBottom]="true">
      <mat-table
        [dataSource]="datasource()"
        matSortActive="name"
        matSortDirection="asc"
        class="m-0 p-0"
        matSort
        (mouseleave)="mouseLeaveContent()">
        <ng-container matColumnDef="icon">
          <mat-header-cell *matHeaderCellDef class="w-[4rem] flex-initial" mat-sort-header />
          <mat-cell *matCellDef="let item" class="w-[4rem] flex-initial justify-center justify-items-center">
            <app-item-icon [item]="item" />
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef class="flex-grow" mat-sort-header>Name</mat-header-cell>
          <mat-cell *matCellDef="let item" class="flex-grow">
            @if (currentlyEditedItemId() !== item.object.id) {
              <span>
                {{ item.object.name }}
              </span>
            } @else {
              <app-name-edition-input
                class="w-full"
                [initialValue]="item.object.name"
                (valueSubmitted)="handleNameChanged(item, $event)"
                (editionCanceled)="renameCanceled.emit(item)"
                (click)="$event.stopPropagation()"
                [isValidFun]="isValidName" />
            }
          </mat-cell>
        </ng-container>

        <ng-containter matColumnDef="signalsCount">
          <mat-header-cell *matHeaderCellDef class="w-[7%] flex-initial" mat-sort-header>Files</mat-header-cell>
          <mat-cell *matCellDef="let item" class="w-[7%] flex-initial">
            {{ displayNFiles(item) }}
          </mat-cell>
        </ng-containter>

        @if (_canEdit) {
          <ng-container matColumnDef="button">
            <mat-header-cell *matHeaderCellDef class="w-[75px] flex-initial" />
            <mat-cell *matCellDef="let item" class="w-[75px] flex-initial">
              <button
                #menuTrigger="matMenuTrigger"
                [matMenuTriggerFor]="menu"
                [matMenuTriggerRestoreFocus]="false"
                mat-icon-button
                (click)="$event.stopPropagation()"
                (menuOpened)="menuOpened()"
                (menuClosed)="menuClosed()">
                <mat-icon>more_horiz</mat-icon>
                <mat-menu #menu="matMenu">
                  <app-context-menu
                    [menuTrigger]="menuTrigger"
                    [allowDownload]="false"
                    [allowOpenInNew]="false"
                    [allowEdit]="_canEdit && !isSensorItem(item)"
                    [allowDelete]="_canEdit"
                    (rename)="renameClicked.emit(item)"
                    (delete)="deleteClicked.emit(item)" />
                </mat-menu>
              </button>
            </mat-cell>
          </ng-container>
        }

        <mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
          (mouseenter)="mouseLeaveContent()"
          [ngStyle]="{ opacity: isEmpty() ? 0 : 1 }" />
        <mat-row
          *matRowDef="let item; let index = index; columns: displayedColumns"
          [ngClass]="{ 'bg-slate-100': item === highligtedItem() }"
          class="cursor-pointer"
          matRipple
          [matRippleDisabled]="true"
          (mouseenter)="mouseEnterItem(item)"
          (click)="onClick(item, index)"
          (contextmenu)="onRightClick($event, item)" />

        <div *matNoDataRow class="flex flex-row justify-center">
          <span
            >Folder is empty.
            @if (_canEdit) {
              You can add subfolder or sensor using above rigth buttons
            }
          </span>
        </div>
      </mat-table>
    </app-scrollable-bordered-container>
    <div class="m-1 flex flex-row items-center gap-4">
      <mat-paginator
        [pageSizeOptions]="[10, 50, 100, 500]"
        showFirstLastButtons
        pageSize="50"
        [ngStyle]="{ opacity: isEmpty() ? 0 : 1 }" />
    </div>
  </div>
</div>

@if (_canEdit) {
  <div
    class="fixed opacity-0"
    #rightTrigger="matMenuTrigger"
    [matMenuTriggerFor]="rightClickMenu"
    [style.left]="rightClickMenuPosition.x"
    [style.top]="rightClickMenuPosition.y"
    (menuOpened)="menuOpened()"
    (menuClosed)="menuClosed()"></div>

  <mat-menu #rightClickMenu="matMenu">
    <ng-template matMenuContent let-item="item">
      <app-context-menu
        [menuTrigger]="rightTrigger"
        [allowDownload]="false"
        [allowOpenInNew]="false"
        [allowEdit]="!isSensorItem(item)"
        [allowDelete]="true"
        (rename)="renameClicked.emit(item)"
        (delete)="deleteClicked.emit(item)" />
    </ng-template>
  </mat-menu>
}
