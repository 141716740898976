import { BaseSelectors } from '@app-types/extra-selectors-factory';
import { createSelector } from '@ngrx/store';
import { ASTRION_INDEXEDDB_NAME, ASTRION_INDEXEDDB_TABLES } from '@shared/constants/astrion-indexeddb';
import { recordMap } from '@tools/utilities/record-utilities';

import { name, SensorGraphsState } from './sensor-graphs.state';

export const extraSelectors = ({
  selectCurrentSensorId,
  selectSensorsCurves,
}: BaseSelectors<typeof name, SensorGraphsState>) => {
  const selectCurves = createSelector(selectCurrentSensorId, selectSensorsCurves, (sensorId, sensorsCurves) =>
    sensorId ? (sensorsCurves[sensorId] ?? {}) : {}
  );
  return {
    selectCurves,
    selectGraphInput: createSelector(selectCurves, curves => ({
      dbName: ASTRION_INDEXEDDB_NAME,
      storeName: ASTRION_INDEXEDDB_TABLES.trajectoryTrend,
      curves: Object.values(curves).sort((a, b) => a.id.localeCompare(b.id)),
    })),
    selectCurveNameToId: createSelector(selectCurves, curves => recordMap(curves, (key, curve) => [curve.id, key])),
    selectCurveIds: createSelector(selectCurves, curves => Object.keys(curves)),
    selectCurveIdsSet: createSelector(selectCurves, curves => new Set(Object.keys(curves))),
  };
};
