import { FolderId } from '@features/folders/shared/interface/folder.interface';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { SensorId } from '../interfaces/sensor.interface';
import { Sensor, SensorPayload } from '../interfaces/sensor.interface';

export const SensorsActions = createActionGroup({
  source: 'Sensors',
  events: {
    noop: emptyProps(),
    refreshIfNotInit: emptyProps(),

    sensorsFetchRequestedIfNotInit: emptyProps(),
    sensorsFetchRequested: emptyProps(),
    sensorsFetchFailed: emptyProps(),
    sensorsFetched: props<{ sensors: Sensor[] }>(),

    sensorCreateRequested: props<{ sensor: SensorPayload }>(),
    sensorCreated: props<{ sensor: Sensor }>(),
    sensorCreateFailed: emptyProps(),

    sensorDeleteRequested: props<{ id: SensorId; folderId: FolderId; force: boolean }>(),
    sensorDeleted: props<{ id: SensorId; folderId: FolderId }>(),
    sensorDeleteFailed: emptyProps(),
  },
});
