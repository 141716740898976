@let _statuses = statuses();

<div class="relative flex h-full w-full flex-col">
  @if (loading()) {
    <mat-progress-bar class="absolute -top-1 left-0 z-50 w-full" mode="indeterminate" />
  }
  <div class="mb-1 flex w-full flex-row items-center">
    <span class="m3-headline-small flex-none">Sensor Signals</span>
    <button mat-icon-button matTooltip="Refresh signals" (click)="refreshSignals()">
      <mat-icon>refresh</mat-icon>
    </button>
    @if (canEdit()) {
      <button mat-icon-button matTooltip="Upload signals" (click)="openUploadDialog()">
        <mat-icon>upload_file</mat-icon>
      </button>
    }
    @if (canRecompute() && !isEmpty()) {
      <button
        matTooltip="Recompute"
        #menuTriggerCompute="matMenuTrigger"
        [matMenuTriggerFor]="menu"
        [matMenuTriggerRestoreFocus]="false"
        mat-icon-button
        (click)="$event.stopPropagation()"
        (menuOpened)="menuOpened()"
        (menuClosed)="menuClosed()">
        <mat-icon>play_circle</mat-icon>
        <mat-menu #menu="matMenu">
          <app-compute-menu
            [menuTrigger]="menuTriggerCompute"
            (recomputeDataValidation)="flagDataValidationAsDirty()"
            (recomputePeakIdentification)="flagPeakIdentificationAsDirty()" />
        </mat-menu>
      </button>
    }
    <button
      mat-flat-button
      class="ml-auto"
      matTooltip="Show selected data in graph"
      [disabled]="!nonStationnaritySelected.value"
      (click)="visualize()">
      <mat-icon>insights</mat-icon>Visualize
    </button>
  </div>
  <app-scrollable-bordered-container class="flex-grow" [fadeTop]="true" [fadeBottom]="true" (dragenter)="onDragStart()">
    <mat-table
      [dataSource]="datasource()"
      [trackBy]="trackSignalId"
      matSortActive="name"
      matSortDirection="asc"
      class="m-0 p-0"
      matSort
      (mouseleave)="mouseLeaveContent()">
      <ng-container matColumnDef="status">
        <mat-header-cell
          *matHeaderCellDef
          class="surface border-outline-variant w-[4rem] flex-none rounded-tl-lg"
          mat-sort-header
          ><mat-icon matTooltip="Status" matTooltipShowDelay="500">playlist_add_check_circle</mat-icon></mat-header-cell
        >
        <mat-cell
          *matCellDef="let item"
          class="fg-on-surface-variant border-outline-variant w-[4rem] flex-none justify-center justify-items-center bg-white">
          <app-signal-icon [signalStatus]="_statuses[item.id]?.status" />
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef class="surface border-outline-variant w-[20rem] flex-none" mat-sort-header
          >Name</mat-header-cell
        >
        <mat-cell
          *matCellDef="let item"
          class="fg-on-surface-variant border-outline-variant w-[20rem] flex-none bg-white"
          [matTooltip]="'description: ' + item.description"
          [matTooltipDisabled]="item.description.length <= 0"
          [matTooltipShowDelay]="400"
          matTooltipPosition="above">
          <!-- TODO code in if/else exists also in explorer-content-component -->
          @if (currentlyEditedNameId() !== item.id) {
            <span class="w-full">
              {{ item.name }}
            </span>
          } @else {
            <app-name-edition-input
              class="w-full"
              [initialValue]="item.name"
              (valueSubmitted)="changeName(item, $event)"
              (editionCanceled)="cancelEditName()"
              (click)="$event.stopPropagation()" />
          }
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef class="surface border-outline-variant w-[7rem] flex-none" mat-sort-header
          >Date</mat-header-cell
        >
        <mat-cell
          *matCellDef="let item"
          class="fg-on-surface-variant border-outline-variant w-[7rem] flex-none justify-center bg-white text-center">
          @if (item.date) {
            <span
              [class.fg-warning]="hasDuplicatedDate(item.id)"
              [matTooltipDisabled]="!hasDuplicatedDate(item.id)"
              matTooltip="Signal will not be used for trajectories because of duplicate date"
              matTooltipPosition="right"
              matTooltipShowDelay="400"
              >{{ item.date | date: 'yyyy/MM/dd HH:mm:ss' }}</span
            >
          } @else {
            <mat-icon
              class="fg-warning"
              matTooltip="Signal will not be used for trajectories because of missing date"
              matTooltipPosition="right"
              matTooltipShowDelay="400"
              >warning</mat-icon
            >
          }
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="validationFlags">
        <mat-header-cell
          *matHeaderCellDef
          data-testid="validationFlags"
          class="surface border-outline-variant w-[7rem] flex-none cursor-pointer"
          ><span>Validation</span>
          @if (validationFlagsCollapsed()) {
            <mat-icon
              class="fg-primary"
              (click)="toggleValidationFlags()"
              matTooltip="Expand validation flags"
              matTooltipShowDelay="500"
              matTooltipPosition="above">
              keyboard_arrow_right
            </mat-icon>
          } @else {
            <mat-icon
              class="fg-primary"
              (click)="toggleValidationFlags()"
              matTooltip="Collapse validation flags"
              matTooltipShowDelay="500"
              matTooltipPosition="above">
              keyboard_arrow_left
            </mat-icon>
          }
        </mat-header-cell>
        <mat-cell
          *matCellDef="let item"
          class="fg-on-surface-variant border-outline-variant flex w-[7rem] flex-none items-center justify-center bg-white">
          @if (validationFlagsCollapsed()) {
            <app-validation-flags-color-headband
              class="h-full w-[3rem] py-2"
              [flags]="_statuses[item.id]?.validationFlags" />
          }
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="timeSaturation">
        <mat-header-cell
          [@addColumn]
          *matHeaderCellDef
          data-testid="timeSaturation"
          class="surface border-outline-variant w-[10rem] flex-none cursor-pointer pl-1"
          matTooltip="Collapse validation flags"
          matTooltipPosition="above"
          ><span>Time Saturation</span></mat-header-cell
        >
        <mat-cell
          [@addColumn]
          *matCellDef="let item"
          class="fg-on-surface-variant border-outline-variant w-[10rem] flex-none bg-white px-1">
          <app-time-saturation-validation-flag
            class="h-full w-full py-2"
            [flag]="_statuses[item.id]?.validationFlags?.timeSaturation" />
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="shannonTest">
        <mat-header-cell [@addColumn] *matHeaderCellDef class="surface border-outline-variant w-[10rem] flex-none"
          >Shannon Test</mat-header-cell
        >
        <mat-cell
          [@addColumn]
          *matCellDef="let item"
          class="fg-on-surface-variant border-outline-variant w-[10rem] flex-none bg-white px-1">
          <app-shannon-validation-flag
            class="h-full w-full py-2"
            [flag]="_statuses[item.id]?.validationFlags?.shannon" />
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="nonStationnarity">
        <mat-header-cell
          [@addColumn]
          *matHeaderCellDef
          data-testid="nonStationnarityColumn"
          class="surface border-outline-variant w-[10rem] flex-none">
          <mat-checkbox matTooltip="Select to visualize in graph" [formControl]="nonStationnaritySelected" />Non
          Stationnarity</mat-header-cell
        >
        <mat-cell
          [@addColumn]
          *matCellDef="let item"
          class="fg-on-surface-variant border-outline-variant w-[10rem] flex-none bg-white px-1">
          <app-percentage-validation-flag
            class="h-full w-full py-2"
            [flag]="_statuses[item.id]?.validationFlags?.nonStationnarity" />
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="samplesCount">
        <mat-header-cell *matHeaderCellDef class="surface border-outline-variant" mat-sort-header
          >Samples</mat-header-cell
        >
        <mat-cell *matCellDef="let item" class="fg-on-surface-variant border-outline-variant bg-white">
          {{ item.samplesCount | number }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="samplingFrequency">
        <mat-header-cell *matHeaderCellDef class="surface border-outline-variant" mat-sort-header>
          Sampling
        </mat-header-cell>
        <mat-cell *matCellDef="let item" class="fg-on-surface-variant border-outline-variant bg-white">
          {{ item.samplingFrequency | number }} Hz
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="duration">
        <mat-header-cell *matHeaderCellDef class="surface border-outline-variant" mat-sort-header
          >Duration</mat-header-cell
        >
        <mat-cell *matCellDef="let item" class="fg-on-surface-variant border-outline-variant bg-white">
          {{ getDuration(item) | duration }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="button">
        <mat-header-cell *matHeaderCellDef class="surface border-outline-variant w-[75px] flex-initial rounded-tr-lg" />
        <mat-cell
          *matCellDef="let item"
          class="fg-on-surface-variant border-outline-variant w-[75px] flex-initial bg-white">
          <button
            #menuTrigger="matMenuTrigger"
            [matMenuTriggerFor]="menu"
            [matMenuTriggerRestoreFocus]="false"
            mat-icon-button
            (click)="$event.stopPropagation()"
            (menuOpened)="menuOpened()"
            (menuClosed)="menuClosed()">
            <mat-icon>more_horiz</mat-icon>
            <mat-menu #menu="matMenu">
              <app-context-menu
                [menuTrigger]="menuTrigger"
                [allowDownload]="true"
                [allowOpenInNew]="true"
                [allowEdit]="canEdit()"
                [allowDelete]="canEdit()"
                (openInNew)="openInNew(item)"
                (download)="downloadSignal(item)"
                (rename)="editName(item)"
                (delete)="delete(item)" />
            </mat-menu>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="displayedColumns(); sticky: true"
        (mouseenter)="mouseLeaveContent()"
        class="text-ms"
        [ngStyle]="{ opacity: isEmpty() ? 0 : 1 }" />
      <mat-row
        *matRowDef="let item; let index = index; columns: displayedColumns()"
        [ngClass]="{ 'bg-slate-100': item === highligtedItem() }"
        class="cursor-pointer"
        matRipple
        [matRippleDisabled]="true"
        (mouseenter)="mouseEnterItem(item)"
        (click)="onClick($event, item, index)"
        (contextmenu)="onRightClick($event, item)" />
    </mat-table>
    <app-drag-and-drop
      [canUpload]="canEdit()"
      (dragStopped)="onDragStop()"
      class="absolute inset-0"
      (filesDropped)="onFilesDrop($event)"
      [ngStyle]="{ 'z-index': showDragging() ? 200 : -200, visibility: showDragging() ? 'visible' : 'hidden' }" />
  </app-scrollable-bordered-container>
  <div class="m-1 flex flex-row items-center justify-between">
    <mat-paginator
      [pageSizeOptions]="[10, 50, 100, 500]"
      showFirstLastButtons
      pageSize="50"
      [ngStyle]="{ opacity: isEmpty() ? 0 : 1 }" />
    @if (!isEmpty()) {
      <div class="mat-paginator-font-size flex flex-col">
        <div>Computed signals: {{ nCompletedSignals() }} / {{ nItems() }}</div>
        <mat-progress-bar mode="determinate" [value]="computationPercent()" />
      </div>
    }
    <div class="filler w-[600px]"></div>
  </div>
</div>

<div
  class="fixed opacity-0"
  #rightTrigger="matMenuTrigger"
  [matMenuTriggerFor]="rightClickMenu"
  [style.left]="rightClickMenuPosition.x"
  [style.top]="rightClickMenuPosition.y"
  (menuOpened)="menuOpened()"
  (menuClosed)="menuClosed()"></div>

<mat-menu #rightClickMenu="matMenu">
  <ng-template matMenuContent let-item="item">
    <app-context-menu
      [menuTrigger]="rightTrigger"
      [allowDownload]="true"
      [allowOpenInNew]="true"
      [allowEdit]="canEdit()"
      [allowDelete]="canEdit()"
      (openInNew)="openInNew(item)"
      (download)="downloadSignal(item)"
      (rename)="editName(item)"
      (delete)="delete(item)" />
  </ng-template>
</mat-menu>
