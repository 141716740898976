import { FlattenedFolderTree, FolderDto, FolderId, HOME_FOLDER } from '../interface/folder.interface';

export default function folderTreeFlattener(rootFolders: FolderDto[]): FlattenedFolderTree {
  const homeFolder: FolderDto = {
    id: HOME_FOLDER.id,
    name: HOME_FOLDER.name,
    parentId: null,
    children: rootFolders,
  };

  return folderTreeFlattenerOnFolder(homeFolder, HOME_FOLDER.parentId);
}

function folderTreeFlattenerOnFolder(folder: FolderDto, parentId: FolderId): FlattenedFolderTree {
  const initial_value: FlattenedFolderTree = {
    folders: {
      [folder.id]: {
        id: folder.id,
        name: folder.name,
        isLeaf: folder.children.length === 0,
        parentId: parentId,
      },
    },
    children: { [folder.id]: folder.children.map((subfolder: FolderDto) => subfolder.id) },
  };
  return folder.children.reduce((acc: FlattenedFolderTree, currentFolder: FolderDto) => {
    const { folders, children } = folderTreeFlattenerOnFolder(currentFolder, folder.id);
    return {
      folders: { ...acc.folders, ...folders },
      children: { ...acc.children, ...children },
    };
  }, initial_value);
}
