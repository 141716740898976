import { FlattenedFolderTree, HOME_FOLDER } from '../interface/folder.interface';
import { FoldersUri } from '../utils/folders-uri';

export const name = 'folders';

export interface FoldersState {
  initialized: boolean;
  flattenedFoldersTree: FlattenedFolderTree;
  foldersUri: FoldersUri;
}

export const FOLDERS_INITIAL_STATE: FoldersState = {
  initialized: false,
  flattenedFoldersTree: {
    folders: {
      [HOME_FOLDER.id]: HOME_FOLDER,
    },
    children: {
      [HOME_FOLDER.id]: [],
    },
  },
  foldersUri: {
    folderIdToUri: {
      [HOME_FOLDER.id]: '',
    },
    uriToFolderId: {
      '': HOME_FOLDER.id,
    },
  },
};
