@let _showingAll = showingAll();
@let _allLoaded = allLoaded();

<div class="flex w-full flex-col">
  @if (cyclesData(); as cyclesData) {
    @if (_showingAll && _allLoaded) {
      @for (cycle of cyclesData; track $index; let index = $index, last = $last) {
        <lib-gl-graph
          class="my-1 h-[15vh] flex-grow"
          [data]="cyclesData[index]"
          [id]="graphId(index)"
          [yScale]="yScale()"
          xTitle="Frequency (Hz)"
          [yTitle]="yTitle()"
          xDisplay="axis"
          [yMeanTicks]="4"
          group="cycle-graphs"
          [lines]="cyclesLines()[index]" />
      }
    } @else if (currentLoaded()) {
      <lib-gl-graph
        class="mx-2 h-[60vh]"
        [data]="currentCycleData()"
        xTitle="Frequency (Hz)"
        [yTitle]="yTitle()"
        id="cycle-graph-current"
        [yScale]="yScale()"
        [lines]="currentCycleLines()" />
    }

    <app-peaks-graph-interactive-legend
      class="mx-8"
      [dbScale]="dbScale()"
      (dbScaleChange)="dbScale.set($event)"
      [curveOptions]="curveOptions"
      [peaks]="_showingAll ? [] : currentCyclePeaks()"
      (peaksPredicateChanged)="peaksFilter.set($event)">
      @if (cycles().length > 1) {
        <div class="flex flex-row justify-center gap-4 px-8 py-4">
          <mat-slide-toggle class="my-auto" [checked]="_showingAll" (change)="showingAllChanged($event)">
            Show all
          </mat-slide-toggle>

          <mat-button-toggle-group
            name="cycles"
            [disabled]="_showingAll"
            [value]="activeCycleIndex()"
            (valueChange)="activeCycleIndex.set($event)">
            @for (cycle of cycles(); track cycle.index) {
              <mat-button-toggle [value]="cycle.index">Cycle {{ cycle.index + 1 }}</mat-button-toggle>
            }
          </mat-button-toggle-group>
        </div>
      } @else {
        <button mat-stroked-button class="secondary-button" (click)="exportCsv()">Export CSV</button>
      }
    </app-peaks-graph-interactive-legend>

    @if (!_showingAll) {
      <app-peaks-table class="mx-8 mt-5 h-[60vh]" [peaks]="currentCyclePeaks()" [filterPredicate]="peaksFilter()" />
    }

    @if (displayAdditionalData()) {
      <app-estimation-arrays
        [cycleIndex]="activeCycleIndex()"
        [cycleSpectrumData]="currentCycleSpectrumData()"
        [cycleNoiseData]="currentCycleNoiseData()" />
    }
  }
</div>
