<div class="flex h-full w-full flex-col content-stretch items-stretch p-0">
  @if (folder()!; as folder) {
    @if (path()!; as path) {
      <app-explorer-top-bar class="flex-none" [path]="path" (navigateToFolder)="navigateToFolder($event)" />
      <app-explorer-content
        class="flex-grow"
        [folder]="folder"
        [content]="content()"
        [loading]="isLoading()"
        [currentlyEditedItemId]="currentlyEditedItemId()"
        (itemClicked)="itemClicked($event)"
        (deleteClicked)="deleteItem($event)"
        (renameClicked)="renameClicked($event)"
        (refreshClicked)="refresh()"
        (createFolder)="createFolder($event)"
        (renameCanceled)="renameCanceled($event)"
        (itemNameChanged)="updateItem($event)" />
    }
  }
</div>
