import { EmptyGuid, Guid } from '@shared/types/guid.type';

export type FolderId = Guid;

export interface FolderDto {
  id: FolderId;
  name: string;
  parentId: FolderId | null;
  children: FolderDto[];
}

export interface FolderPayload {
  name: string;
  parentId: FolderId | null;
}

export interface Folder {
  id: FolderId;
  name: string;
  parentId: FolderId;
  isLeaf: boolean;
  status?: FolderStatus | null;
}

export enum FolderStatus {
  Updating,
}

export type FoldersMap = Record<FolderId, Folder>;
export type FolderChildrenMap = Record<FolderId, FolderId[]>;

export interface FlattenedFolderTree {
  folders: FoldersMap;
  children: FolderChildrenMap;
}

export const HOME_FOLDER: Folder = {
  id: EmptyGuid,
  name: 'Home',
  isLeaf: false,
  parentId: EmptyGuid,
};

export const mapFolderId = (folderId: FolderId | null) => folderId ?? HOME_FOLDER.id;
export const mapFromFolderId = (folderId: FolderId) => (folderId === HOME_FOLDER.id ? null : folderId);

export const isFolderNotEmpty = (folder: Folder) => !folder.isLeaf;
