import { FlattenedFolderTree, FolderId, FoldersMap } from '../interface/folder.interface';

export const getDescentSet = (flattenedFoldersTree: FlattenedFolderTree, folderId: FolderId): Set<FolderId> =>
  new Set(getDescent(flattenedFoldersTree, folderId));

export const getAncestorsSet = (foldersMap: FoldersMap, folderId: FolderId): Set<FolderId> =>
  new Set(getAncestors(foldersMap, folderId));

const getDescent = (flattenedFoldersTree: FlattenedFolderTree, folderId: FolderId): FolderId[] => {
  return flattenedFoldersTree.children[folderId].reduce(
    (idList: FolderId[], currentFolderId: FolderId) => [
      ...idList,
      ...getDescent(flattenedFoldersTree, currentFolderId),
    ],
    [folderId]
  );
};

const getAncestors = (foldersMap: FoldersMap, folderId: FolderId): FolderId[] => {
  const ancestors: FolderId[] = [folderId];
  let folder = foldersMap[folderId];
  while (folder.id !== folder.parentId) {
    folder = foldersMap[folder.parentId];
    ancestors.push(folder.id);
  }
  return ancestors;
};
