import { Guid } from '@app-types/guid.type';
import { FilterModel } from '@components/filter-dialog/filter-dialog.component';

export interface TrajectoryDto {
  id: Guid;
  name: string;
  frequency: number;
  detectionPercentage: number;
  frequencyTrendMiniatureContentPath: string;
  energyTrendMiniatureContentPath: string;
}

export interface SensorTrajectoriesDto {
  status: string;
  nextComputationDate: Date | null;
  trajectories: TrajectoryDto[];
}

export interface Trajectory {
  id: Guid;
  name: string;
  frequency: number;
  detectionPercentage: number;
  frequencyTrendMiniatureContentPath: string;
  energyTrendMiniatureContentPath: string;
}

export interface SensorTrajectories {
  status: string;
  nextComputationDate: Date | null;
  trajectories: Trajectory[];
}

export function filterByFrequency(trajectories: Trajectory[] | null, filter: FilterModel | undefined) {
  if (filter && trajectories) {
    return trajectories.filter(d => filter.min <= d.frequency && d.frequency <= filter.max);
  }
  return trajectories;
}

export function filterByDetectionPercentage(trajectories: Trajectory[] | null, filter: FilterModel | undefined) {
  if (filter && trajectories) {
    return trajectories.filter(d => filter.min <= d.detectionPercentage && d.detectionPercentage <= filter.max);
  }
  return trajectories;
}
