import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, model, output, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { CurveId, GraphCurve, GraphInput } from '@astrion-webtools/graph';
import { MaterialModule } from '@modules/material.module';
import { toggleFromImmutableSet } from '@tools/utilities/set-utilities';

import { DisplayColorPipe } from '../display-color.pipe';
import { GroupName, GroupState, GroupStateMap } from '../trajectory-graph.interface';
import { TrajectoryGraphColorSelectComponent } from '../trajectory-graph-color-select/trajectory-graph-color-select.component';

@Component({
  selector: 'app-trajectory-graph-settings',
  standalone: true,
  imports: [MaterialModule, CommonModule, FormsModule, TrajectoryGraphColorSelectComponent, DisplayColorPipe],
  templateUrl: './trajectory-graph-settings.component.html',
  styleUrl: './trajectory-graph-settings.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrajectoryGraphSettingsComponent {
  data = input.required<GraphInput>();
  groups = input.required<GroupName[]>();
  visibleGroups = input.required<Set<GroupName | undefined>>();
  groupStates = model.required<GroupStateMap>();

  curvesGrouped = output<CurveId[]>();
  curvesUngrouped = output<CurveId[]>();
  removeCurve = output<CurveId>();
  focusedCurve = output<CurveId | undefined>();
  colorSelected = output<{ name: CurveId; color: string }>();

  selection = signal<Set<CurveId>>(new Set());
  hasSelection = computed(() => this.selection().size > 0);

  groupTogglesChanged(event: MatButtonToggleChange, groupName: string | undefined) {
    if (groupName === '__undefined__') {
      groupName = undefined;
    }

    this.groupStates.update(states => {
      const values = event.value as ('hide' | 'single')[];
      const newStates = new Map(states);
      if (values.length === 0) {
        newStates.delete(groupName);
      } else if (values.length === 1) {
        newStates.set(groupName, values[0] === 'single' ? GroupState.Single : GroupState.Hide);
      } else {
        // length == 2
        const lastValue = states.get(groupName);
        const lastValueString = lastValue === GroupState.Single ? 'single' : 'hide';
        const newValue =
          values.filter(v => v !== lastValueString)[0] === 'single' ? GroupState.Single : GroupState.Hide;
        newStates.set(groupName, newValue);
      }
      return newStates;
    });
  }

  getGroupToggle(groupName: string | undefined): string[] {
    const state = this.groupStates().get(groupName);
    return state ? [state === GroupState.Single ? 'single' : 'hide'] : [];
  }

  isGroupVisible(groupName: GroupName | undefined) {
    return this.visibleGroups().has(groupName);
  }

  onCurveEnter(name: CurveId, visible: boolean) {
    if (visible) {
      this.focusedCurve.emit(name);
    }
  }

  onCurveLeave() {
    this.focusedCurve.emit(undefined);
  }

  groupedData = computed<{ groupName: GroupName | undefined; curves: GraphCurve[] }[]>(() => {
    const data = this.data();
    const groups = this.groups();
    const curves = data.curves ?? [];

    const grouped = [...[...groups].sort(), undefined].map(groupName => ({
      groupName,
      curves: curves.filter(curve => curve.axisGroup === groupName),
    }));

    return grouped.filter(group => group.curves.length !== 0);
  });

  nGroups = computed(() => this.groupedData().length);

  onRemoveCurve(name: CurveId) {
    this.removeCurve.emit(name);
  }

  legendClicked(name: CurveId) {
    this.selection.update(selection => toggleFromImmutableSet(selection, name));
  }

  groupSelection() {
    this.curvesGrouped.emit(Array.from(this.selection()));
    this.selection.set(new Set());
  }

  ungroupSelection() {
    this.curvesUngrouped.emit(Array.from(this.selection()));
    this.selection.set(new Set());
  }

  getCurveName(curve: GraphCurve): string {
    return curve.displayName ?? curve.id;
  }
}
