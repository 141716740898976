import { Component, computed, input } from '@angular/core';
import { GlGraphComponent, GraphHeatmap, GraphInput } from '@astrion-webtools/graph';
import { GraphId } from '@astrion-webtools/graph/lib/gl-graph/interface/graph-id.type';
import { ASTRION_INDEXEDDB_NAME, ASTRION_INDEXEDDB_TABLES } from '@shared/constants/astrion-indexeddb';

@Component({
  selector: 'app-stationarity-heatmap',
  standalone: true,
  imports: [GlGraphComponent],
  templateUrl: './stationarity-heatmap.component.html',
  styles: ``,
})
export class StationarityHeatmapComponent {
  id = input.required<GraphId>();
  heatmap = input.required<GraphHeatmap | undefined>();
  graphTitle = input.required<string>();

  data = computed<GraphInput>(() => {
    const heatmap = this.heatmap();
    return {
      heatmaps: heatmap ? [heatmap] : undefined,
      dbName: ASTRION_INDEXEDDB_NAME,
      storeName: ASTRION_INDEXEDDB_TABLES.stationarityTestImages,
    };
  });
}
