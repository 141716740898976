import { Injectable } from '@angular/core';
import { mapFolderId } from '@features/folders/shared/interface/folder.interface';
import { RealtimeClientService } from '@features/realtime/shared/services/realtime-client.service';
import { AStrionSignal, AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { AStrionSignalUploadStatus } from '@features/signals/shared/interface/astrion-signal-upload-result.interface';
import { SignalsActions } from '@features/signals/shared/store/signals.actions';
import { string2Status } from '@features/signals-status/shared/interface/astrion-signals-status.mapper';
import { Store } from '@ngrx/store';
import { ProcessingStatus } from '@shared/interfaces/processing-status';
import { firstValueFrom } from 'rxjs';

import { SensorSignalsActions } from '../store/sensor-signals.actions';
import { sensorSignalsFeature } from '../store/sensor-signals.feature';
import {
  SignalDeletedMessage,
  SignalEditedMessage,
  SignalStepStatusChanged,
  SignalUploadedMessage,
} from './interfaces';

@Injectable({
  providedIn: 'root',
})
export class SensorSignalsRealtimeRegistrationService {
  constructor(private store: Store) {}

  public registerMessageHandlers(realtimeClient: RealtimeClientService): void {
    realtimeClient.registerMessageHandlers([
      { messageType: 'SignalUploaded', callback: this.signalUploadedHandler },
      { messageType: 'SignalEdited', callback: this.signalEditedHandler },
      { messageType: 'SignalDeleted', callback: this.signalDeletedHandler },
      { messageType: 'PeakIdentificationComputationStatusChanged', callback: this.signalStepStatusChangedHandler },
      { messageType: 'DataValidationComputationStatusChanged', callback: this.signalStepStatusChangedHandler },
      { messageType: 'StationarityTestComputed', callback: this.signalDataValidationStepCompletedHandler },
      { messageType: 'TimeSaturationTestComputed', callback: this.signalDataValidationStepCompletedHandler },
      { messageType: 'SamplingTestComputed', callback: this.signalDataValidationStepCompletedHandler },
    ]);
    realtimeClient.registerMultipleMessagesHandlers([
      {
        messageType: 'PeakIdentificationComputationStatusChanged',
        callback: this.multipleSignalStepStatusChangedHandler,
      },
      { messageType: 'DataValidationComputationStatusChanged', callback: this.multipleSignalStepStatusChangedHandler },
    ]);
  }

  private signalUploadedHandler = async (msg: unknown): Promise<void> => {
    const signalData = msg as SignalUploadedMessage;

    const newSignal: AStrionSignal = {
      id: signalData.signalId,
      name: signalData.name,
      sensorId: signalData.sensorId,
      createdAt: new Date(signalData.createdAt),
      date: signalData.date ? new Date(signalData.date) : undefined,
      samplingFrequency: signalData.samplingFrequency,
      samplesCount: signalData.samplesCount,
      description: signalData.description,
    };

    const signalUploadResult = {
      name: newSignal.name,
      status: AStrionSignalUploadStatus.Success,
      signal: newSignal,
    };

    this.store.dispatch(SignalsActions.signalsUploaded({ uploads: [signalUploadResult] }));
  };

  private signalEditedHandler = async (msg: unknown): Promise<void> => {
    const signalData = msg as SignalEditedMessage;

    const currentSensorId = this.store.selectSignal(sensorSignalsFeature.selectSensorId);
    if (currentSensorId() !== signalData.sensorId) {
      return;
    }

    const signal = this.store
      .selectSignal(sensorSignalsFeature.selectSignals)()
      .find(s => s.id === signalData.signalId);

    if (signal) {
      this.store.dispatch(
        SignalsActions.signalUpdated({
          signal: {
            ...signal,
            name: signalData.name,
          },
        })
      );
    }
  };

  private signalDeletedHandler = (msg: unknown): void => {
    const signalData = msg as SignalDeletedMessage;

    const folderId = mapFolderId(signalData.folderId);

    this.store.dispatch(SignalsActions.signalDeleted({ signalId: signalData.signalId, sensorId: folderId }));
  };

  private signalStepStatusChangedHandler = async (msg: unknown): Promise<void> => {
    this.multipleSignalStepStatusChangedHandler([msg]);
  };

  private multipleSignalStepStatusChangedHandler = async (msgs: unknown[]): Promise<void> => {
    const signalData = msgs as SignalStepStatusChanged[];
    if (string2Status(signalData[0].newStatus) in [ProcessingStatus.Dirty, ProcessingStatus.InProgress]) {
      const statuses = signalData.reduce(
        (acc, item) => {
          acc[item.signalId] = {
            status: string2Status(item.newStatus),
          };
          return acc;
        },
        {} as Record<AStrionSignalId, { status: ProcessingStatus }>
      );
      this.store.dispatch(SignalsActions.signalsStatusFetched({ statuses }));
    } else {
      const currentSignals = this.store.selectSignal(sensorSignalsFeature.selectSignals)();
      const signalIdsToFetch = currentSignals.filter(cs => signalData.find(s => cs.id == s.signalId)).map(s => s.id);

      if (signalIdsToFetch.length > 0) {
        this.store.dispatch(SignalsActions.signalsStatusFetchRequested({ signalIds: signalIdsToFetch }));
      }
    }
  };

  private signalDataValidationStepCompletedHandler = async (msg: unknown): Promise<void> => {
    const signalData = msg as SignalStepStatusChanged;

    const isInCurrentSensor = await firstValueFrom(
      this.store.select(sensorSignalsFeature.selectContainsSignal(signalData.signalId))
    );

    if (isInCurrentSensor) {
      this.store.dispatch(SensorSignalsActions.validationFlagsFetchRequested({ signalId: signalData.signalId }));
    }
  };
}
