import { FoldersActions } from '@features/folders/shared/store/folders.actions';
import { SignalsActions } from '@features/signals/shared/store/signals.actions';
import { createReducer, on } from '@ngrx/store';

import { FilesExplorerActions } from './files-explorer.actions';
import { FILES_EXPLORER_INITIAL_STATE, FilesExplorersState } from './files-explorer.state';

export const reducer = createReducer(
  FILES_EXPLORER_INITIAL_STATE,
  on(
    FoldersActions.foldersFetchRequested,
    (state: FilesExplorersState): FilesExplorersState => ({
      ...state,
      isLoading: true,
    })
  ),
  on(
    FoldersActions.foldersFetched,
    (state: FilesExplorersState): FilesExplorersState => ({
      ...state,
      isLoading: false,
    })
  ),
  on(
    FilesExplorerActions.fetchFolderSkipAlreadyCached,
    (state: FilesExplorersState, { folder }): FilesExplorersState => ({
      ...state,
      isLoading: false,
      currentFolderId: folder.id,
    })
  ),
  on(
    FilesExplorerActions.fetchFolderSkipNoContent,
    (state: FilesExplorersState, { folder }): FilesExplorersState => ({
      ...state,
      isLoading: false,
      currentFolderId: folder.id,
    })
  ),
  on(
    FoldersActions.foldersFetchFailed,
    SignalsActions.sensorSignalsFetchFailed,
    (state: FilesExplorersState): FilesExplorersState => ({
      ...state,
      isLoading: false,
    })
  ),
  on(
    FilesExplorerActions.itemEditionRequested,
    (state: FilesExplorersState, { id }): FilesExplorersState => ({
      ...state,
      currentlyEditedItemId: id,
    })
  ),
  on(
    FilesExplorerActions.editedItemCleared,
    (state: FilesExplorersState): FilesExplorersState => ({
      ...state,
      currentlyEditedItemId: null,
    })
  )
);
