<mat-toolbar
  class="m3-headline-small-button flex w-full flex-row justify-between p-0"
  (mouseenter)="mouseInNav.set(false)"
  (mouseleave)="mouseInNav.set(true)">
  <div class="relative flex flex-grow flex-row justify-stretch">
    <div [@path]="state()" class="z-10 flex flex-row items-center overflow-clip">
      @if (loading()) {
        <button mat-icon-button matTooltip="Home folder" class="opacity-0"><mat-icon>home</mat-icon></button>
      } @else {
        @for (folder of folders(); track folder.id; let first = $first) {
          @if (first) {
            <button mat-icon-button matTooltip="Home folder" (click)="this.folderClicked.emit(folder)">
              <mat-icon class="m3-headline-small-icon">home</mat-icon>
            </button>
          } @else {
            <mat-icon class="m3-headline-small-icon">chevron_right</mat-icon>
            <button mat-button (click)="this.folderClicked.emit(folder)">{{ folder.name }}</button>
          }
        }
        @if (sensor(); as sensor) {
          <mat-icon class="m3-headline-small-icon">chevron_right</mat-icon>
          <button mat-button (click)="this.sensorClicked.emit(sensor)">{{ sensor.name }}</button>
        }
      }
    </div>

    @if (filename(); as filename) {
      <div [@filename]="state()" class="absolute inset-0 flex flex-row items-center justify-center">
        <h1>
          {{ filename }}
        </h1>
      </div>
    }
  </div>
  <div class="flex flex-none flex-row"><ng-content /></div>
</mat-toolbar>
