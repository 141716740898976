import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  output,
  Signal,
  signal,
  viewChild,
  viewChildren,
} from '@angular/core';
import { MatRipple } from '@angular/material/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NameEditionInputComponent } from '@components/name-edition-input/name-edition-input.component';
import { ScrollableBorderedContainerComponent } from '@components/scrollable-bordered-container/scrollable-bordered-container.component';
import { authFeature } from '@features/auth/shared/store/auth.feature';
import {
  ExplorerFolderItem,
  ExplorerItem,
  isFolderItem,
  isSensorItem,
  itemSignalsCount,
} from '@features/files-explorer/shared/interface/explorer-item.interface';
import { OrderItemByPipe } from '@features/files-explorer/shared/pipes/order-item-by.pipe';
import { Folder, FolderId } from '@features/folders/shared/interface/folder.interface';
import { CreateSensorDialogService } from '@features/sensors/shared/services/create-sensor-dialog.service';
import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { MaterialModule } from '@modules/material.module';
import { Store } from '@ngrx/store';
import { DialogService } from '@services/dialog.service';
import { DragAndDropComponent } from '@shared/components/drag-and-drop/drag-and-drop.component';
import { DurationPipe } from '@tools/pipes/duration.pipe';
import { isValidName } from '@tools/utilities/encode-uri-strict';

import { ContextMenuComponent } from '../../../../shared/components/context-menu/context-menu.component';
import { ItemIconComponent } from '../item-icon/item-icon.component';

type TableExplorerItem = ExplorerItem & { lowerName: string; highlighted: boolean };

@Component({
  selector: 'app-explorer-content',
  templateUrl: './explorer-content.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MaterialModule,
    ContextMenuComponent,
    NameEditionInputComponent,
    OrderItemByPipe,
    ScrollableBorderedContainerComponent,
    DurationPipe,
    DragAndDropComponent,
    ItemIconComponent,
  ],
  providers: [DialogService, CreateSensorDialogService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExplorerContentComponent {
  folder = input.required<Folder>();
  content = input.required<ExplorerItem[][]>();
  currentlyEditedItemId = input<FolderId | AStrionSignalId | null>(null);
  loading = input<boolean>(false);

  itemClicked = output<ExplorerItem>();
  deleteClicked = output<ExplorerItem>();
  renameClicked = output<ExplorerItem>();
  renameCanceled = output<ExplorerItem>();
  itemNameChanged = output<ExplorerItem>();
  refreshClicked = output();
  createFolder = output<string>();

  matRipples = viewChildren<MatRipple>(MatRipple);
  rightTrigger = viewChild<MatMenuTrigger>('rightTrigger');

  private _sort = viewChild.required(MatSort);
  private _paginator = viewChild(MatPaginator);

  public items: Signal<TableExplorerItem[]> = computed(() =>
    this.content()
      .flat()
      .map(item => ({ ...item, lowerName: item.object.name.toLocaleLowerCase(), highlighted: false }))
      .sort((a, b) => a.lowerName.localeCompare(b.lowerName))
  );

  public hasSensors = computed(() => this.items().some(i => isSensorItem(i)));

  public highligtedItem = signal<TableExplorerItem | null>(null);
  private _menuOpened = false;
  private _mouseEnteredDuringMenuOpened = false;

  public canEdit = this.store.selectSignal(authFeature.selectCanEdit);
  public isAstriisUser = this.store.selectSignal(authFeature.selectIsAstriisUser);

  // drag and drop
  isDragging = signal<boolean>(false);
  isEmpty = computed(() => this.items().length <= 0);
  showDragging = computed(() => this.isDragging() || (!this.loading() && this.isEmpty()));

  nItems = computed(() => this.items().length);

  public hasPaginator = computed(() => this.items.length > 50);

  datasource = computed(() => {
    const sort = this._sort();
    const paginator = this._paginator();
    const items = this.items();
    const datasource = new MatTableDataSource(items);
    datasource.sort = sort;
    datasource.sortingDataAccessor = (item: TableExplorerItem, sortHeaderId: string) => {
      switch (sortHeaderId) {
        case 'icon':
          if (isFolderItem(item)) {
            return item.hasSensor ? 1 : 0;
          }
          return 2;
        case 'name':
          return item.lowerName;
        case 'signalsCount':
          return itemSignalsCount(item);
        default:
          return 0;
      }
    };
    datasource.paginator = paginator ?? null;
    return datasource;
  });

  public displayedColumns = this.canEdit()
    ? ['icon', 'name', 'signalsCount', 'button']
    : ['icon', 'name', 'signalsCount'];

  constructor(
    private dialogService: DialogService,
    private store: Store,
    private createSensorDialogService: CreateSensorDialogService
  ) {}

  public rightClickMenuPosition = { x: '0', y: '0' };

  public handleNameChanged(item: ExplorerItem, newName: string) {
    this.itemNameChanged.emit({ ...item, object: { ...item.object, name: newName } });
  }

  public onClick(item: ExplorerItem, index: number) {
    this.matRipples()[index]?.launch({ centered: true });
    this.itemClicked.emit(item);
  }

  public onRightClick(event: MouseEvent, item: ExplorerItem) {
    event.preventDefault();
    event.stopPropagation();
    this.rightClickMenuPosition.x = event.clientX + 'px';
    this.rightClickMenuPosition.y = event.clientY + 'px';
    if (this.canEdit()) {
      this.rightTrigger()!.menuData = { item };
      this.rightTrigger()!.openMenu();
    }
  }

  public displayNFiles(item: ExplorerFolderItem) {
    const count = itemSignalsCount(item);
    if (count > 0) {
      return `${count}`;
    } else {
      return '-';
    }
  }

  public menuOpened() {
    this._menuOpened = true;
  }

  public menuClosed() {
    this._menuOpened = false;
    if (!this._mouseEnteredDuringMenuOpened) {
      this.highligtedItem.set(null);
    }
    this._mouseEnteredDuringMenuOpened = false;
  }

  public mouseEnterItem(item: TableExplorerItem) {
    this.highligtedItem.set(item);
    if (this._menuOpened) {
      this._mouseEnteredDuringMenuOpened = true;
    }
  }

  public mouseLeaveContent() {
    if (!this._menuOpened) {
      this.highligtedItem.set(null);
    }
  }

  isValidName = isValidName;

  isSensorItem = isSensorItem;

  async openCreateFolderDialog() {
    const name = await this.dialogService.input();
    if (name) {
      this.createFolder.emit(name);
    }
  }

  async openCreateSensorDialog() {
    this.createSensorDialogService.open({ folderId: this.folder().id });
  }
}
