import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { FoldersBarComponent } from '@features/folders/components/folders-bar/folders-bar.component';
import { Folder } from '@features/folders/shared/interface/folder.interface';
import { Sensor } from '@features/sensors/shared/interfaces/sensor.interface';
import { MaterialModule } from '@modules/material.module';

@Component({
  selector: 'app-explorer-top-bar',
  templateUrl: './explorer-top-bar.component.html',
  standalone: true,
  imports: [FoldersBarComponent, MaterialModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExplorerTopBarComponent {
  path = input.required<Folder[]>();
  sensor = input<Sensor>();

  navigateToFolder = output<Folder>();
  navigateToSensor = output<Sensor>();
}
