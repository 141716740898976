import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DataValidationModule } from '@features/data-validation/data-validation.module';
import { PeakIdentificationModule } from '@features/peak-identification/peak-identification.module';
import { SignalOverviewModule } from '@features/signal-overview/signal-overview.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { FILES_EXPLORER_ROUTES } from './files-explorer.routes';
import { FilesExplorerEffects } from './shared/store/files-explorer.effects';
import { filesExplorerFeature } from './shared/store/files-explorer.feature';

@NgModule({
  imports: [
    RouterModule.forChild(FILES_EXPLORER_ROUTES),
    EffectsModule.forFeature([FilesExplorerEffects]),
    StoreModule.forFeature(filesExplorerFeature),
    SignalOverviewModule,
    DataValidationModule,
    PeakIdentificationModule,
  ],
})
export class FilesExplorerModule {}
