import { Folder } from '@features/folders/shared/interface/folder.interface';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { ExplorerItemId } from '../interface/explorer-item.interface';

export const FilesExplorerActions = createActionGroup({
  source: 'FilesExplorer',
  events: {
    itemEditionRequested: props<{ id: ExplorerItemId }>(),
    editedItemCleared: props<{ fromId: ExplorerItemId }>(),

    navigationToFolderRequested: props<{ folder: Folder }>(),

    fetchFolderSkipNoContent: props<{ folder: Folder }>(),
    fetchFolderSkipAlreadyCached: props<{ folder: Folder }>(),

    refreshIfNotInit: emptyProps(),
    refresh: emptyProps(),
  },
});
