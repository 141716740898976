import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataValidationSensorApiService } from '@features/data-validation/shared/services/data-validation-sensor-api.service';
import { ValidationFlagsApiService } from '@features/data-validation/shared/services/validation-flags-api.service';
import { validationFlagsFromDto } from '@features/data-validation/shared/utils/validation-flags-mapping';
import { UploadStatusDialogComponent } from '@features/files-explorer/components/upload-status-dialog/upload-status-dialog.component';
import { PeakIdentificationSensorApiService } from '@features/peak-identification/shared/services/peak-identification-sensor-api.service';
import { TrajectoriesApiService } from '@features/sensor-trajectories/services/trajectories-api/trajectories-api.service';
import { SignalsActions } from '@features/signals/shared/store/signals.actions';
import { catchApiError } from '@modules/error-handling/app-error.operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, map, tap } from 'rxjs';

import { SensorSignalsActions } from './sensor-signals.actions';

@Injectable()
export class SensorSignalsEffects {
  constructor(
    private actions$: Actions,
    private dialog: MatDialog,
    private errorSnackbar: MatSnackBar,
    private validationFlagsApiService: ValidationFlagsApiService,
    private dataValidationSensorApiService: DataValidationSensorApiService,
    private peakIdentificationSensorApiService: PeakIdentificationSensorApiService,
    private trajectoriesApiService: TrajectoriesApiService
  ) {}

  fetchValidationFlags$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SensorSignalsActions.validationFlagsFetchRequested),
      concatMap(({ signalId }) =>
        this.validationFlagsApiService.getValidationFlags(signalId).pipe(
          map(dto =>
            SensorSignalsActions.validationFlagsFetched({ signalId, validationFlags: validationFlagsFromDto(dto) })
          ),
          catchApiError(false, () => SensorSignalsActions.validationFlagsFetchfailed())
        )
      )
    );
  });

  signalUploadStarts$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SignalsActions.signalsUploadStarted),
        tap(() => {
          this.dialog.open(UploadStatusDialogComponent, {
            minWidth: '30vw',
            minHeight: '50vh',
            disableClose: true,
          });
        })
      );
    },
    { dispatch: false }
  );

  flagDataValidationAsDirty$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SensorSignalsActions.flagSensorDataValidationAsDirtyRequested),
      concatMap(({ sensorId }) =>
        this.dataValidationSensorApiService.flagAsDirty(sensorId).pipe(
          map(() => SensorSignalsActions.noAction()),
          catchApiError(false, () => SensorSignalsActions.flagSensorAsDirtyFailed())
        )
      )
    );
  });

  flagPeakIdentificationAsDirty$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SensorSignalsActions.flagSensorPeakIdentificationAsDirtyRequested),
      concatMap(({ sensorId }) =>
        this.peakIdentificationSensorApiService.flagAsDirty(sensorId).pipe(
          map(() => SensorSignalsActions.noAction()),
          catchApiError(false, () => SensorSignalsActions.flagSensorAsDirtyFailed())
        )
      )
    );
  });

  flagTrackingAsDirty$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SensorSignalsActions.flagSensorTrackingAsDirtyRequested),
      concatMap(({ sensorId }) =>
        this.trajectoriesApiService.flagAsDirty(sensorId).pipe(
          map(() => SensorSignalsActions.noAction()),
          catchApiError(false, () => SensorSignalsActions.flagSensorAsDirtyFailed())
        )
      )
    );
  });

  dirtyFailed$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SensorSignalsActions.flagSensorAsDirtyFailed),
        tap(() =>
          this.errorSnackbar.open('Impossible to recompute data', 'Dismiss', {
            duration: 5000,
          })
        )
      );
    },
    { dispatch: false }
  );
}
