import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { LoadingState } from '@shared/interfaces/loading-state';

import { Sensor } from '../interfaces/sensor.interface';

export const name = 'sensors';

export interface SensorsState {
  loadingState: LoadingState;
  sensors: Sensor[];
  uploadedSignals: AStrionSignalId[];
  deletedSignals: AStrionSignalId[];
}

export const SENSORS_INITIAL_STATE: SensorsState = {
  loadingState: LoadingState.Unknown,
  sensors: [],
  uploadedSignals: [],
  deletedSignals: [],
};
