import { Folder, FolderId, isFolderNotEmpty } from '@features/folders/shared/interface/folder.interface';
import { Sensor, SensorId } from '@features/sensors/shared/interfaces/sensor.interface';

export enum ExplorerItemType {
  Folder,
  Sensor,
}

export type ExplorerItemId = FolderId | SensorId;

export interface ExplorerItem {
  type: ExplorerItemType;
  object: Folder | Sensor;
}

export interface ExplorerFolderItem extends ExplorerItem {
  type: ExplorerItemType.Folder;
  object: Folder;
  hasSensor: boolean;
  sensorSignalsCount: number;
}

export interface ExplorerSensorItem extends ExplorerItem {
  type: ExplorerItemType.Sensor;
  object: Sensor;
}

export function itemSignalsCount(item: ExplorerItem) {
  switch (item.type) {
    case ExplorerItemType.Folder:
      return (item as ExplorerFolderItem).sensorSignalsCount;
    case ExplorerItemType.Sensor:
      return (item as ExplorerSensorItem).object.signalsCount;
  }
}

export function isItemNotEmpty(item: ExplorerItem) {
  switch (item.type) {
    case ExplorerItemType.Folder:
      return isFolderItemNotEmpty(item as ExplorerFolderItem);
    case ExplorerItemType.Sensor:
      return isSensorItemNotEmpty(item as ExplorerSensorItem);
  }
}

export function isFolderItemNotEmpty(item: ExplorerFolderItem) {
  return isFolderNotEmpty(item.object) || item.hasSensor;
}

export function isSensorItemNotEmpty(item: ExplorerSensorItem) {
  return item.object.signalsCount !== 0;
}

export function isFolderItem(explorerItem: ExplorerItem): explorerItem is ExplorerFolderItem {
  return explorerItem.type == ExplorerItemType.Folder;
}

export function isSensorItem(explorerItem: ExplorerItem): explorerItem is ExplorerSensorItem {
  return explorerItem.type == ExplorerItemType.Sensor;
}
