import { ChangeDetectionStrategy, Component, computed, input, signal, viewChildren } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { GlGraphComponent, GraphCurve, GraphInput, Scaling } from '@astrion-webtools/graph';
import { Cycle } from '@features/peak-identification/shared/interface/cycles';
import { peakToLine } from '@features/peak-identification/shared/utils/peak-utils';
import { MaterialModule } from '@modules/material.module';
import { ASTRION_INDEXEDDB_NAME, ASTRION_INDEXEDDB_TABLES } from '@shared/constants/astrion-indexeddb';
import { loaded } from '@shared/interfaces/loading-state';

import { ContentStyle, ContentToggleComponent } from '../content-toggle/content-toggle.component';
import {
  CurvesOptions,
  PeaksGraphInteractiveLegendComponent,
  SimplifiedPeak,
} from '../peaks-graph-interactive-legend/peaks-graph-interactive-legend.component';
import { PeaksTableComponent } from '../peaks-table/peaks-table.component';
import { EstimationArraysComponent } from './estimation-arrays/estimation-arrays.component';

@Component({
  selector: 'app-cycles',
  standalone: true,
  imports: [
    MaterialModule,
    GlGraphComponent,
    EstimationArraysComponent,
    ContentToggleComponent,
    PeaksGraphInteractiveLegendComponent,
    PeaksTableComponent,
  ],
  templateUrl: './cycles.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CyclesComponent {
  public cycles = input.required<Cycle[]>();

  public graphs = viewChildren(GlGraphComponent);

  public showSpectrum = signal<boolean>(true);
  public showNoiseCurve = signal<boolean>(true);

  public activeCycleIndex = signal<number>(0);
  public showingAll = signal(false);

  public dbScale = signal(true);
  public yScale = computed(() => (this.dbScale() ? Scaling.dB : Scaling.None));

  public yTitle = computed(() => (this.dbScale() ? 'PSD (dB)' : 'PSD'));

  public get spectrumStyle(): ContentStyle {
    return {
      color: '#2FA7E4',
    };
  }

  public get noiseCurveStyle(): ContentStyle {
    return {
      color: '#C72FE4',
    };
  }

  public get curveOptions(): CurvesOptions {
    return {
      name: 'Curves',
      first: {
        name: 'Spectrum',
        style: this.spectrumStyle,
        state: this.showSpectrum,
      },
      additional: [
        {
          name: 'Noise',
          style: this.noiseCurveStyle,
          state: this.showNoiseCurve,
        },
      ],
    };
  }

  public peaksFilter = signal<(peak: SimplifiedPeak) => boolean>(() => true);

  public cyclesLines = computed(() =>
    this.cycles().map(cycle => {
      return cycle.peaks.filter(this.peaksFilter()).map(peak => peakToLine(peak, false));
    })
  );

  public allLoaded = computed<boolean>(() => {
    const cycles = this.cycles();
    return (
      cycles.length !== 0 && cycles.every(cycle => loaded(cycle.spectrum.amplitudes) && loaded(cycle.noise.amplitudes))
    );
  });

  public currentLoaded = computed<boolean>(() => {
    const currentCycle = this.cycles()[this.activeCycleIndex()];
    return loaded(currentCycle.spectrum.amplitudes) && loaded(currentCycle.noise.amplitudes);
  });

  public cyclesData = computed<GraphInput[]>(() => {
    const showSpectrum = this.showSpectrum();
    const showNoise = this.showNoiseCurve();

    return this.cycles().map((cycle): GraphInput => {
      const curves: GraphCurve[] = [];
      if (showSpectrum && loaded(cycle.spectrum.amplitudes)) {
        curves.push({
          id: 'Spectrum',
          color: this.spectrumStyle.color,
          data: {
            indexedDb: {
              id: cycle.spectrum.amplitudes.data!,
              valuesField: 'data',
              xMinField: 'freqMin',
              xMaxField: 'freqMax',
            },
          },
        });
      }

      if (showNoise && loaded(cycle.noise.amplitudes)) {
        curves.push({
          id: 'Noise',
          color: this.noiseCurveStyle.color,
          data: {
            indexedDb: {
              id: cycle.noise.amplitudes.data!,
              valuesField: 'data',
              xMinField: 'freqMin',
              xMaxField: 'freqMax',
            },
          },
        });
      }

      return {
        dbName: ASTRION_INDEXEDDB_NAME,
        storeName: ASTRION_INDEXEDDB_TABLES.cycles,
        curves,
      };
    });
  });

  public currentCycleData = computed(() => {
    const cyclesData = this.cyclesData();
    const activeCycleIndex = this.activeCycleIndex();

    return cyclesData[activeCycleIndex];
  });

  public currentCyclePeaks = computed(() => {
    return this.cycles()[this.activeCycleIndex()].peaks;
  });

  public currentCycleLines = computed(() => {
    const cyclesLines = this.cyclesLines();
    const activeCycleIndex = this.activeCycleIndex();

    return cyclesLines[activeCycleIndex];
  });

  public displayAdditionalData = computed(() => {
    if (this.showingAll()) {
      return false;
    }

    const cycles = this.cycles();

    return cycles.length != 0 && cycles[0].spectrum.data !== undefined;
  });

  public currentCycleSpectrumData = computed(() => {
    return this.cycles()[this.activeCycleIndex()].spectrum.data!;
  });

  public currentCycleNoiseData = computed(() => {
    return this.cycles()[this.activeCycleIndex()].noise.data!;
  });

  public graphId = (index: number) => `cycle-graph-${index}`;

  public showingAllChanged = (event: MatSlideToggleChange) => {
    this.showingAll.set(event.checked);
  };

  public exportCsv = () => {
    const graphs = this.graphs();

    if (graphs.length !== 1) {
      return;
    }

    const graph = graphs[0];

    graph.exportCsv({ xTitle: 'Frequency' }).then(csvContent => {
      const file = new window.Blob([csvContent], { type: 'text/csv' });

      const downloadAnchor = document.createElement('a');
      const fileUrl = URL.createObjectURL(file);

      downloadAnchor.href = fileUrl;
      downloadAnchor.download = `cycle_data.csv`;
      downloadAnchor.click();
    });
  };
}
