import { ChangeDetectionStrategy, Component, Signal } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from '@components/confirm-dialog/confirm-dialog.component';
import { ExplorerContentComponent } from '@features/files-explorer/components/explorer-content/explorer-content.component';
import { ExplorerTopBarComponent } from '@features/files-explorer/components/explorer-top-bar/explorer-top-bar.component';
import {
  ExplorerItem,
  isFolderItem,
  isItemNotEmpty,
  isSensorItem,
} from '@features/files-explorer/shared/interface/explorer-item.interface';
import { FilesExplorerActions } from '@features/files-explorer/shared/store/files-explorer.actions';
import { filesExplorerFeature } from '@features/files-explorer/shared/store/files-explorer.feature';
import {
  selectCurrentFolder,
  selectCurrentFolderId,
  selectExplorerContent,
  selectUriFoldersPath,
} from '@features/files-explorer/shared/store/files-explorer.selectors';
import { Folder, FolderId } from '@features/folders/shared/interface/folder.interface';
import { FoldersActions } from '@features/folders/shared/store/folders.actions';
import { SensorComponent } from '@features/sensors/components/sensor/sensor.component';
import { SensorsActions } from '@features/sensors/shared/store/sensors.actions';
import { AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { Store } from '@ngrx/store';
import { DialogService } from '@services/dialog.service';

@Component({
  selector: 'app-file-explorer',
  templateUrl: './files-explorer.component.html',
  standalone: true,
  imports: [ExplorerTopBarComponent, ExplorerContentComponent, SensorComponent, ConfirmDialogComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialogService],
})
export class FilesExplorerComponent {
  content: Signal<ExplorerItem[][]> = this.store.selectSignal(selectExplorerContent);
  folderId = this.store.selectSignal(selectCurrentFolderId);

  path = this.store.selectSignal(selectUriFoldersPath);
  folder = this.store.selectSignal(selectCurrentFolder);
  isLoading = this.store.selectSignal(filesExplorerFeature.selectIsLoading);

  currentlyEditedItemId: Signal<FolderId | AStrionSignalId | null> = this.store.selectSignal(
    filesExplorerFeature.selectCurrentlyEditedItemId
  );

  constructor(
    private store: Store,
    private dialogService: DialogService,
    private router: Router
  ) {
    this.store.dispatch(FilesExplorerActions.refreshIfNotInit());
  }

  itemClicked(item: ExplorerItem) {
    if (isFolderItem(item)) {
      this.store.dispatch(FilesExplorerActions.navigationToFolderRequested({ folder: item.object }));
    }
    if (isSensorItem(item)) {
      this.router.navigateByUrl(`/sensor/${item.object.id}`);
    }
  }

  renameClicked(item: ExplorerItem) {
    this.store.dispatch(FilesExplorerActions.itemEditionRequested({ id: item.object.id }));
  }

  renameCanceled(item: ExplorerItem) {
    this.store.dispatch(FilesExplorerActions.editedItemCleared({ fromId: item.object.id }));
  }

  async deleteItem(item: ExplorerItem) {
    if (isItemNotEmpty(item)) {
      const wordings = isFolderItem(item)
        ? {
            title: 'Delete folder',
            question: 'Folder is not empty, contents will be deleted recursively',
          }
        : {
            title: 'Delete sensor',
            question: 'Sensor is not empty, contents will be deleted recursively',
          };
      if (await this.dialogService.confirm(wordings)) {
        this.dispatchDeleteItem(item, true);
      }
    } else {
      this.dispatchDeleteItem(item, false);
    }
  }

  updateItem(item: ExplorerItem) {
    if (isFolderItem(item)) {
      this.store.dispatch(FoldersActions.folderUpdateRequested({ folder: item.object }));
    }
  }

  navigateToFolder(folder: Folder) {
    this.store.dispatch(FilesExplorerActions.navigationToFolderRequested({ folder }));
  }

  refresh() {
    this.store.dispatch(FilesExplorerActions.refresh());
  }

  createFolder(name: string) {
    this.store.dispatch(FoldersActions.folderCreationRequested({ name, parentId: this.folder()!.id }));
  }

  private dispatchDeleteItem(item: ExplorerItem, force: boolean) {
    if (isFolderItem(item)) {
      this.store.dispatch(
        FoldersActions.folderDeletionRequested({
          folderId: item.object.id,
          parentId: item.object.parentId,
          force,
        })
      );
    }
    if (isSensorItem(item)) {
      this.store.dispatch(
        SensorsActions.sensorDeleteRequested({
          id: item.object.id,
          folderId: item.object.folderId,
          force,
        })
      );
    }
  }
}
