import { GraphCurve } from '@astrion-webtools/graph';

import { TrajectoryTrendDto } from '../interfaces/trajectory-trend.interface';

export function getTrajectoryTrendCurve(trajectoryTrend: TrajectoryTrendDto): GraphCurve {
  return {
    id: trajectoryTrend.id,
    displayName: trajectoryTrend.name,
    data: {
      indexedDb: {
        id: trajectoryTrend.interlacedValues,
        valuesField: 'interlacedValues',
      },
    },
  };
}
