<div class="flex h-full w-full flex-col content-stretch items-stretch p-0">
  @if (path(); as path) {
    <app-explorer-top-bar
      class="flex-none"
      [path]="path"
      [sensor]="sensor()"
      (navigateToFolder)="navigateToFolder($event)" />
  }
  @if (sensor(); as sensor) {
    <app-sensor class="flex-grow" [sensorSummary]="sensor" [isAFolder]="false" [loading]="isLoading()" />
  }
</div>
