import { SensorId } from '@features/sensors/shared/interfaces/sensor.interface';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ProcessingStatus } from '@shared/interfaces/processing-status';

import { SensorTrajectories, Trajectory } from '../interfaces/trajectory.interface';

export const SensorTrajectoriesActions = createActionGroup({
  source: 'SensorTrajectories',
  events: {
    trajectoriesFetchRequested: props<{ sensorId: SensorId }>(),
    trajectoriesFetchFailed: props<{ sensorId: SensorId }>(),
    trajectoriesNotFound: props<{ sensorId: SensorId }>(),
    trajectoriesFetched: props<{ sensorId: SensorId; sensorTrajectories: SensorTrajectories }>(),

    trajectoriesStatusChanged: props<{
      sensorId: SensorId;
      status: ProcessingStatus;
      nextComputationDate: Date | null;
    }>(),

    trajectoriesFrequencyTrendVisualizeRequested: props<{ trajectory: Trajectory }>(),
    trajectoriesFrequencyTrendFetchFailed: emptyProps(),

    trajectoriesEnergyTrendVisualizeRequested: props<{ trajectory: Trajectory }>(),
    trajectoriesEnergyTrendFetchFailed: emptyProps(),
  },
});
