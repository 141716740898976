@if (loaded() || loading()) {
  <mat-drawer-container class="h-full w-full bg-slate-50 p-0">
    <mat-drawer mode="side" opened class="w-72">
      <app-report-navigation [pages]="pages()" [scrolledAnchor]="scrolledAnchor()" />
    </mat-drawer>
    <mat-drawer-content class="flex flex-col overflow-x-hidden">
      <app-folders-bar
        [folders]="folders()"
        [sensor]="sensor()"
        [filename]="reportTitle()"
        class="flex-initial"
        (folderClicked)="folderClicked.emit($event)"
        (sensorClicked)="sensorClicked.emit($event)">
        <button mat-icon-button matTooltip="Refresh report data" class="primary-button" (click)="onRefreshClicked()">
          <mat-icon>refresh</mat-icon>
        </button>
        <button
          mat-icon-button
          matTooltip="Download signal"
          aria-label="Button that allows to download the file in the current report."
          [disabled]="loading()"
          class="primary-button"
          (click)="downloadClicked.emit()">
          <mat-icon>download_file</mat-icon>
        </button>
      </app-folders-bar>
      <app-report-content-container
        class="mb-10 flex-grow"
        [loading]="loading()"
        (scrolledAnchor)="scrolledAnchor.set($event)" />
    </mat-drawer-content>
  </mat-drawer-container>
} @else {
  <div class="flex h-full w-full items-center justify-center bg-slate-50 p-0">
    <div class="text-center">
      @if (error()) {
        <h1 class="text-4xl font-medium">Error</h1>
        <p class="m-6 text-xl font-medium">Something went wrong. The signal could not be loaded from the server.</p>
      } @else if (notFound()) {
        <h1 class="text-4xl font-medium">Not Found</h1>
        <p class="m-6 text-xl font-medium">
          The signal could not be found, either it is not on the server or it is in another project.
        </p>
      }
      <a mat-raised-button extended [routerLink]="['/']">
        <mat-icon>home</mat-icon>
        Get back to project root
      </a>
    </div>
  </div>
}
