import { Injectable, Signal } from '@angular/core';
import { GraphCurve } from '@astrion-webtools/graph';
import { SensorGraphsService } from '@features/sensor-graphs/shared/service/sensor-graphs.service';
import { SensorId } from '@features/sensors/shared/interfaces/sensor.interface';
import { AStrionSignal, AStrionSignalId } from '@features/signals/shared/interface/astrion-signal.interface';
import { SignalsActions } from '@features/signals/shared/store/signals.actions';
import { Store } from '@ngrx/store';

import { SensorSignalsActions } from '../store/sensor-signals.actions';
import { sensorSignalsFeature } from '../store/sensor-signals.feature';

const NON_STATIONNARITY_TREND_ID = 'non-stationnarity';

@Injectable()
export class SensorSignalsService {
  constructor(
    private store: Store,
    private sensorGraphsService: SensorGraphsService
  ) {}

  setSensorDataValidationAsDirty(sensorId: SensorId) {
    this.store.dispatch(SensorSignalsActions.flagSensorDataValidationAsDirtyRequested({ sensorId }));
  }

  setSensorPeakIdentificationAsDirty(sensorId: SensorId) {
    this.store.dispatch(SensorSignalsActions.flagSensorPeakIdentificationAsDirtyRequested({ sensorId }));
  }

  selectCurrentSensorSignals() {
    return this.store.selectSignal(sensorSignalsFeature.selectSignals);
  }

  selectCurrentSensorSignalStatuses() {
    return this.store.selectSignal(sensorSignalsFeature.selectStatuses);
  }

  selectCurrentSensorDuplicateDateSignalIds(): Signal<AStrionSignalId[]> {
    return this.store.selectSignal(sensorSignalsFeature.selectDuplicateDateSignalId);
  }

  visualizeNonStationnarityCurve() {
    const values = this.store.selectSignal(sensorSignalsFeature.selectNonStationarityValues)();
    const curve: GraphCurve = {
      id: 'Non stationnarity rate',
      color: '#1074ff',
      data: {
        array: {
          keepFloat64: true,
          values,
        },
      },
    };
    this.sensorGraphsService.addCurve(NON_STATIONNARITY_TREND_ID, curve);
  }

  fetchSignals(sensorId: SensorId) {
    this.store.dispatch(SignalsActions.sensorSignalsFetchRequested({ sensorId: sensorId }));
  }

  uploadFiles(files: File[], sensorId: SensorId) {
    this.store.dispatch(SignalsActions.signalsUploadRequested({ sensorId, files }));
  }

  downloadSignal(signalId: AStrionSignalId) {
    this.store.dispatch(SignalsActions.signalDownloadRequested({ signalId }));
  }

  deleteSignal(signal: AStrionSignal) {
    this.store.dispatch(SignalsActions.signalDeletionRequested({ signal }));
  }

  changeSignalName(signal: AStrionSignal, newName: string) {
    this.store.dispatch(SignalsActions.signalUpdateRequested({ signal: { ...signal, name: newName } }));
  }
}
