import { AuthActions } from '@features/auth/shared/store/auth.actions';
import { deleteFolderWithDescendance } from '@features/folders/shared/utils/folder-deletion';
import { createReducer, on } from '@ngrx/store';

import { FolderStatus } from '../interface/folder.interface';
import { addToFoldersUri, deleteSetFromFoldersUri, getFoldersUri } from '../utils/folders-uri';
import { updateFolder, updateFolderStatus, updateFolderStatusById } from '../utils/reducer-helpers';
import { getDescentSet } from '../utils/tree-paths';
import { FoldersActions } from './folders.actions';
import { FOLDERS_INITIAL_STATE, FoldersState } from './folders.state';

export const reducer = createReducer(
  FOLDERS_INITIAL_STATE,
  // Invalidate state if project changed
  on(AuthActions.projectChanged, (): FoldersState => FOLDERS_INITIAL_STATE),
  on(
    FoldersActions.foldersFetched,
    (state: FoldersState, { flattenedFoldersTree }): FoldersState => ({
      ...state,
      initialized: true,
      flattenedFoldersTree,
      foldersUri: getFoldersUri(flattenedFoldersTree),
    })
  ),
  on(FoldersActions.foldersFetchFailed, (state: FoldersState): FoldersState => ({ ...state, initialized: true })),
  on(
    FoldersActions.folderUpdateRequested,
    (state: FoldersState, { folder }): FoldersState => updateFolderStatus(state, folder, FolderStatus.Updating)
  ),
  on(
    FoldersActions.folderDeletionRequested,
    (state: FoldersState, { folderId }): FoldersState => updateFolderStatusById(state, folderId, FolderStatus.Updating)
  ),
  on(FoldersActions.folderDeleted, (state: FoldersState, { folderId }): FoldersState => {
    if (!state.flattenedFoldersTree.folders[folderId]) {
      return state;
    }

    const descendance = getDescentSet(state.flattenedFoldersTree, folderId);
    return {
      ...state,
      flattenedFoldersTree: deleteFolderWithDescendance(state.flattenedFoldersTree, folderId, descendance),
      foldersUri: deleteSetFromFoldersUri(state.foldersUri, descendance),
    };
  }),
  on(FoldersActions.folderCreated, (state: FoldersState, { folder }): FoldersState => {
    if (state.flattenedFoldersTree.folders[folder.id] !== undefined) {
      return state;
    }

    return {
      ...state,
      flattenedFoldersTree: {
        folders: {
          ...state.flattenedFoldersTree.folders,
          [folder.id]: folder,
          [folder.parentId]: { ...state.flattenedFoldersTree.folders[folder.parentId], isLeaf: false },
        },
        children: {
          ...state.flattenedFoldersTree.children,
          [folder.id]: [],
          [folder.parentId]: [...state.flattenedFoldersTree.children[folder.parentId], folder.id],
        },
      },
      foldersUri: addToFoldersUri(state.foldersUri, folder),
    };
  }),
  on(
    FoldersActions.folderUpdateFailed,
    (state: FoldersState, { folder }): FoldersState => updateFolderStatus(state, folder, undefined)
  ),
  on(
    FoldersActions.folderDeletionFailed,
    (state: FoldersState, { folderId }): FoldersState => updateFolderStatusById(state, folderId, undefined)
  ),
  on(FoldersActions.folderUpdated, (state: FoldersState, { folder }): FoldersState => updateFolder(state, folder))
);
