<app-signal-report-display
  [signalId]="signalId()"
  [reportTitle]="reportTitle()"
  [folders]="folders()"
  [sensor]="sensor()"
  [signalLoadingState]="loadingState()"
  [pages]="pages()"
  (downloadClicked)="downloadSignal()"
  (folderClicked)="navigateToFolder($event)"
  (sensorClicked)="navigateToSensor($event)" />
