@if (bannerTheme(); as bannerTheme) {
  @let _hasAdditionalContent = hasAdditionalContent();

  <mat-expansion-panel
    class="mx-auto w-[95%] bg-opacity-80 px-3 py-2"
    [class]="bannerTheme.backgroundColor"
    disabled="true"
    [expanded]="panelExpanded"
    [hideToggle]="true">
    <mat-expansion-panel-header
      class="cursor-default bg-transparent"
      [expandedHeight]="'auto'"
      [collapsedHeight]="'auto'">
      <div
        class="grid w-full grid-cols-[max-content_1fr_max-content] gap-x-3 py-2"
        [class]="_hasAdditionalContent ? 'grid-cols-[max-content_1fr_max-content]' : 'grid-cols-[max-content_1fr]'">
        <div
          class="flex flex-row gap-2 self-start rounded-md bg-opacity-20 px-3 py-2 align-middle"
          [class]="[bannerTheme.buttonBackgroundColor]">
          <mat-icon [style]="{ color: bannerTheme.buttonIconColor }">{{ bannerTheme.buttonIcon }}</mat-icon>
          <span class="text-base" [class]="bannerTheme.buttonTextColor">{{ bannerTheme.buttonText }}</span>
        </div>
        <span class="py-2 pr-3 text-base" [class]="bannerTheme.messageColor">{{ message() }}</span>
        @if (_hasAdditionalContent) {
          <button mat-stroked-button class="place-self-end" [class]="bannerTheme.messageColor" (click)="togglePanel()">
            @if (panelExpanded) {
              Hide details
            } @else {
              Show details
            }
          </button>
        }
      </div>
    </mat-expansion-panel-header>
    <div #additionalContent class="rounded bg-slate-100 bg-opacity-60">
      <ng-content />
    </div>
  </mat-expansion-panel>
}
